import React from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"
import { contactStyles } from "../styles/Contact"

export const StyledBackground = styled("div")`
	${contactStyles}
	background-color: ${props => props.attributes.backgroundColor};
	padding: 30px;
	p {
		font-size: 0.9rem;
	}
	a {
		margin-top: 1rem;
	}
	h2 {
		line-height: 27px;
		${props => props.theme.bp.above.md} {
			max-width: 850px;
			margin: 0 auto;
			font-size: 2rem;
			line-height: 2rem;
			padding-bottom: 1rem;
		}
	}
	.wp-block-subhead {
		padding-top: 1.7rem;
		letter-spacing: 2px;
		font-family: "Rajdhani", sans-serif;
		padding-bottom: 1rem;
		padding-top: 0.5rem;
		${props => props.theme.bp.above.md} {
			font-size: 1.1rem;
			padding-bottom: .5rem;
		}
	}
`

const InnerBlocks = styled("div")``

const Background = ({ block }) => {
	const { className } = block.attributes
	return (
		<StyledBackground
			className={className + " background"}
			attributes={block.attributes}
		>
			{block.innerBlocks && block.innerBlocks.length > 0 && (
				<InnerBlocks>
					{block.innerBlocks.map((child, index) => (
						<BlockRenderer key={index} block={child} />
					))}
				</InnerBlocks>
			)}
		</StyledBackground>
	)
}

export default Background
