import React from "react"

const Details = ({ user }) => {
	if (!user) return null
	return (
		<>
			<div>
				<strong>Company: </strong>
				{user.company}
			</div>
			<div>
				<strong>Name: </strong>
				{user.firstName + " " + user.lastName}
			</div>
			<div>
				<strong>Email: </strong>
				{user.email}
			</div>
			<div>
				<strong>Phone: </strong>
				{user.mobile}
			</div>
			<br />
			<div>
				<strong>Licenses: </strong>
				{user.num_licenses}
			</div>
			<div>
				<strong>Installed version: </strong>
				{user.installed_version}
			</div>
			<div>
				<strong>OPC License: </strong>
				{user.opc_license}
			</div>
			<div>
				<strong>XTool License number: </strong>
				{user.xtool_license_number}
			</div>
		</>
	)
}
export default Details
