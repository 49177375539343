import React, { useState, useContext } from "react";
import { Query } from "react-apollo";
import { Link } from "react-router-dom";
import { loader } from "graphql.macro";
const PostsQuery = loader("./queries/PostsQuery.gql");
const PagesQuery = loader("./queries/PagesQuery.gql");
const ReferencesQuery = loader("./queries/ReferencesQuery.gql");
const ContactsQuery = loader("./queries/ContactsQuery.gql");
const AccessoriesQuery = loader("./queries/AccessoriesQuery.gql");

import styled from "@emotion/styled";

import Reference from "./PostsComponents/Reference";
import BlogPost from "./PostsComponents/BlogPost";
import PagePost from "./PostsComponents/PagePost";
import Contact from "./PostsComponents/Contact";
import PostPopup from "./PostsComponents/PostPopup";
import Accessory from "./PostsComponents/Accessory";
import { LanguageContext } from "./language/LanguageProvider";

const PostsWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 0;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  &.contacts {
    flex-wrap: wrap;
    justify-content: start;
  }
  &.references {
    position: relative;
    &.references-page {
      ${(props) => props.theme.bp.above.md} {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-auto-rows: max-content;
      }
    }
    ${(props) => props.theme.bp.above.md} {
      margin-bottom: 160px;
    }
    ${(props) => props.theme.bp.below.md} {
      margin-bottom: 2rem;
    }
    & > div {
      padding-bottom: 2rem;
    }
    span {
      margin-bottom: -4rem;
      padding-bottom: 0;
      ${(props) => props.theme.bp.below.md} {
        bottom: 40px;
      }
    }
    h3 {
      ${(props) => props.theme.bp.below.md} {
      }
    }
  }
  &.blog-wrapper {
    display: block;
  }
  .reference-link {
    color: #858585;
    margin-bottom: 6rem;
  }
  &.pages {
    background-image: none;
    background: none;
    margin: 0 auto;
    article > div {
      padding-bottom: 1rem;
    }
    h3 {
      padding-top: 1em;
      min-height: auto;
    }
    ${(props) => props.theme.bp.below.md} {
      padding: 0;
    }
    ${(props) => props.theme.bp.above.md} {
      column-count: 3;
      display: block;
      column-gap: 2rem;
      overflow: auto;
      padding: 0;
      max-width: 1100px;
      grid-template-columns: 32% 32% 32%;
      grid-gap: 0;
      display: flex;
      justify-content: end;
      flex-wrap: wrap;
    }
    &.num-pages-2 {
      article {
        max-width: calc(50% - 20px);
        ${(props) => props.theme.bp.below.md} {
          max-width: 100%;
          margin-right: auto;
        }
      }
      article:nth-of-type(3) {
        margin-right: 20px;
      }
      article:nth-of-type(2) {
        margin-right: 0;
        ${(props) => props.theme.bp.below.md} {
          margin-right: auto;
        }
      }
    }
    article {
      max-width: calc(33% - 10px);
      margin-right: 20px;
      margin-left: 0;
      &:nth-of-type(3) {
        margin-right: 0;
      }
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      padding-bottom: 50px !important;

      display: inline-block;

      ${(props) => props.theme.bp.below.md} {
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 50px;
        max-width: 100%;
        &:nth-of-type(3) {
          margin-right: auto;
        }
      }
    }
  }
  &.accessories {
    flex-wrap: wrap;
    justify-content: space-between;
    article {
      width: calc(90% + 20px);
      margin: 0 auto 3rem;
      ${(props) => props.theme.bp.above.md} {
        width: 30%;
        margin: 0 0 3rem;
      }
      padding-bottom: 3rem !important;

      > div {
        padding-bottom: 0;
      }
    }
  }
  span {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;
    bottom: 60px;
    text-transform: uppercase;
    font-family: "Rajdhani", sans-serif;
    letter-spacing: 2px;
    font-size: 0.8rem;
  }
  ${(props) => props.theme.bp.above.md} {
    flex-wrap: nowrap;
    span {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding-bottom: 6rem;
      font-size: 1rem;
    }
  }
`;

const Posts = ({ block, parameters, className, postType, popup, setPopup }) => {
  const { activeLanguage, blogLink, refLink } = useContext(LanguageContext);

  var query = PostsQuery;

  if (postType) {
    if (postType === "page") {
      query = PagesQuery;
    }
    if (postType === "references") {
      query = ReferencesQuery;
    }
    if (postType === "contacts") {
      query = ContactsQuery;
    }
  }
  if (block !== undefined && block.attributes !== undefined) {
    if (block.attributes.postType === "page") {
      query = PagesQuery;
    }
    if (block.attributes.postType === "references") {
      query = ReferencesQuery;
    }
    if (block.attributes.postType === "contacts") {
      query = ContactsQuery;
    }
    if (block.attributes.postType === "accessories") {
      query = AccessoriesQuery;
    }
    var variables = {
      orderBy: block.attributes.orderBy
        ? block.attributes.orderBy.toUpperCase()
        : "MENU_ORDER",
      first: block.attributes.postsToShow,
    };
    if (block.attributes.parentId) {
      variables.parentId = block.attributes.parentId.toString();
    }
  }
  if (parameters) {
    var variables = parameters;
  } else {
    var variables = {
      order: block.attributes.order
        ? block.attributes.order.toUpperCase()
        : "DESC",
      orderBy: block.attributes.orderBy
        ? block.attributes.orderBy.toUpperCase()
        : "MENU_ORDER",
      parentId: block.attributes.parentId,
      first: block.attributes.postsToShow,
    };
  }
  if (block && block.attributes && block.attributes.categories) {
    variables.categoryId = parseFloat(block.attributes.categories);
  }

  variables.language = activeLanguage.toUpperCase();

  var viewAllNewsLinkText = "View all news ";
  var viewAllBusinessCasesLinkText = "View all business cases";
  var viewAllContactsLinkText = "View all contacts";
  var viewAllAccessoriesLinkText = "View all accessories";

  switch (activeLanguage) {
    case "fr":
      viewAllNewsLinkText = "Voir toutes les actualités";
      viewAllBusinessCasesLinkText = "Voir toutes les références";
      viewAllContactsLinkText = "Voir tous les contacts";
      viewAllAccessoriesLinkText = "Voir tous les accessories";
      break;
    case "sv":
      viewAllNewsLinkText = "Se alla nyheter";
      viewAllBusinessCasesLinkText = "Se alla kundreferenser";
      viewAllContactsLinkText = "Se alla kontakter";
      viewAllAccessoriesLinkText = "Se alla tillbehör";
      break;
    default:
      break;
  }

  return (
    <>
      <Query query={query} variables={variables}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) {
            console.warn(error);
            return null;
          }
          if (data.posts && data.posts.nodes) {
            return (
              <PostsWrapper
                className={className + " blog-wrapper posts-wrapper"}
              >
                {data.posts.nodes.map((post, index) => (
                  <>
                    {popup === post.id && (
                      <PostPopup
                        key={post.id}
                        popup={popup}
                        setPopup={setPopup}
                        post={post}
                      />
                    )}
                    {popup === post.slug && (
                      <PostPopup
                        key={post.slug}
                        popup={popup}
                        setPopup={setPopup}
                        post={post}
                      />
                    )}
                    <BlogPost
                      post={post}
                      key={index}
                      parameters={parameters}
                      popup={popup}
                      setPopup={setPopup}
                    />
                  </>
                ))}
                {window.location.pathname == "/" ||
                window.location.pathname == "/sv" ||
                window.location.pathname == "/en" ||
                window.location.pathname == "/fr" ? (
                  <span>
                    <Link className="blog-link" to={blogLink}>
                      {viewAllNewsLinkText}
                    </Link>
                  </span>
                ) : null}
              </PostsWrapper>
            );
          } else if (
            data.pageBy &&
            data.pageBy.childPages &&
            data.pageBy.childPages.nodes
          ) {
            return (
              <PostsWrapper
                className={
                  "pages num-pages-" + data.pageBy.childPages.nodes.length
                }
              >
                {data.pageBy.childPages.nodes.map((post, index) => (
                  <PagePost post={post} key={index} parameters={parameters} />
                ))}
              </PostsWrapper>
            );
          } else if (data.references && data.references.nodes) {
            return (
              <PostsWrapper className={className + " references posts-wrapper"}>
                {data.references.nodes.map((post, index) => (
                  <>
                    {popup == post.slug && (
                      <PostPopup
                        postType="reference"
                        popup={popup}
                        setPopup={setPopup}
                        post={post}
                      />
                    )}
                    {popup == post.id && (
                      <PostPopup
                        postType="reference"
                        popup={popup}
                        setPopup={setPopup}
                        post={post}
                      />
                    )}

                    <Reference
                      popup={popup}
                      setPopup={setPopup}
                      key={index}
                      post={post}
                    />
                  </>
                ))}
                {window.location.pathname == "/" ||
                window.location.pathname == "/sv" ||
                window.location.pathname == "/en" ||
                window.location.pathname == "/fr" ? (
                  <span>
                    <Link className="reference-link" to={refLink}>
                      {viewAllBusinessCasesLinkText}
                    </Link>
                  </span>
                ) : null}
              </PostsWrapper>
            );
          } else if (data.contacts && data.contacts.nodes) {
            return (
              <PostsWrapper className={className + " contacts posts-wrapper"}>
                {data.contacts.nodes.map((post, index) => (
                  <Contact post={post} key={index} />
                ))}
                {/* {window.location.pathname == "/" ||
								window.location.pathname == "/en" ||
								window.location.pathname == "/fr" ? (
									<span>
										<Link to={activeLanguage.contact}>{viewAllContactsLinkText}</Link>
									</span>
								) : null} */}
              </PostsWrapper>
            );
          } else if (data.accessories && data.accessories.nodes) {
            return (
              <PostsWrapper
                className={className + " accessories posts-wrapper"}
              >
                {data.accessories.nodes.map((post, index) => (
                  <Accessory post={post} key={index} />
                ))}
                {/* {window.location.pathname == "/" ||
								window.location.pathname == "/en" ||
								window.location.pathname == "/fr"? (
									<span>
										<Link to="/">{viewAllAccessoriesLinkText}</Link>
									</span>
								) : null} */}
              </PostsWrapper>
            );
          }
        }}
      </Query>
    </>
  );
};

export default Posts;
