import React from "react"
import styled from "@emotion/styled"

import BlockRenderer from "../BlockRenderer"
import MaxWidth from "../layout/MaxWidth"

const SliderWrapper = styled("div")`
	overflow: hidden;
	margin-bottom: 6rem;
	${props => props.theme.bp.below.md} {
		margin-bottom: 0;
	}
`
const Scroller = styled("div")`
	${props => props.theme.bp.below.md} {
		overflow: hidden;
		overflow-x: scroll;
		-webkit-overflow-scrolling: touch;
		overflow-scrolling: touch;
	}
`

const StyledSlider = styled("div")`
	display: flex;
`

export default class Slider extends React.Component {
	constructor(props) {
		super(props)
		this.headerRef = React.createRef()
	}

	render() {
		const { block } = this.props

		return (
			<MaxWidth>
				<SliderWrapper>
					<Scroller ref={this.headerRef}>
						<StyledSlider>
							{block.innerBlocks &&
								block.innerBlocks.length > 0 &&
								block.innerBlocks.map((child, index) => (
									<BlockRenderer key={index} block={child} />
								))}
						</StyledSlider>
					</Scroller>
				</SliderWrapper>
			</MaxWidth>
		)
	}
}
