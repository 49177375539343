import css from "@emotion/css"
import { bp } from "../Theme"
export const cardStyles = props => css`
	background-color: #fff;
	width: 100%;
	margin: 0 auto;
	padding: 50px;
	box-sizing: border-box;
	position: relative;
	z-index: 5;
	margin-bottom: 30px;
	max-width: 1100px;
	${props => props.theme.bp.below.md} {
		padding: 50px 30px;
	}
	p {
		font-size: 0.9rem;
	}
	a {
		margin-top: 1rem;
	}
	h3 {
		padding-top: 1rem;
		padding-bottom: 0.3rem;
	}
	.wp-block-subhead {
		padding-top: 1.7rem;
		letter-spacing: 2px;
		font-family: "Rajdhani", sans-serif;
		&:first-of-type {
			color: #357772;
		}
		a {
			color: #666;
			letter-spacing: 2px;
			font-family: "Rajdhani", sans-serif;
		}
	}
	time {
		font-family: "Rajdhani", sans-serif;
		color: #357772;
	}
	${bp.below.md} {
		.AccordionItem {
			h2 {
				font-size: 1rem;
				line-height: 1.2rem;
				position: relative;
				svg {
					position: absolute;
					right: 5px;
					top: 50%;
					margin-right: 0px;
					margin-top: 0;
					transform: translateY(-50%);
				}
			}
		}
	}
	${bp.above.md} {
		max-width: 1100px;
		&.narrow {
			max-width: 730px;
			margin-bottom: 6rem;
			& > div {
				padding-left: 3rem;
				padding-right: 3rem;
			}
		}
		&.wide {
			margin-bottom: 5rem;
		}
		& > div {
			max-width: 45vw;
			margin: 0 auto;
		}
		div {
			padding-bottom: 1rem;
		}
		h2 {
			font-size: 2rem;
			line-height: 2rem;
			margin-bottom: 0.5rem;
		}
		p {
			font-size: 1rem;
		}
	}
	${bp.below.md} {
		width: 90%;
	}
`
