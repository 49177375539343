import React, { useState } from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"
//import { StyledBackground } from "./Background"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleUp, faAngleDown } from "@fortawesome/pro-light-svg-icons"
library.add(faAngleUp, faAngleDown)

const StyledAccordionItem = styled("div")`
	border-bottom: 1px solid #b9b9b9;
	padding: 10px 0;
	padding-bottom: 10px !important;
	h2 {
		cursor: pointer;
		margin: 0;
		padding-left: 10px;
		font-size: 1.3rem;
		font-weight: normal;
		display: flex;
		justify-content: space-between;
		align-items: center;
		span {
			padding-right: 10px;
		}
		svg {
			float: right;
			margin-right: 10px;
			margin-top: 5px;
			color: #b9b9b9;
			font-size: 2rem;
		}
	}
`

const AccordionItemContent = styled("section")`
	max-height: 0;
	transition: 0.2s;
	overflow: hidden;
	&.open {
		max-height: 3000px;
	}
`

const AccordionItem = ({ block }) => {
	const { title } = block.attributes
	const [open, setOpen] = useState(false)
	return (
		<StyledAccordionItem className="AccordionItem">
			<h2 onClick={() => setOpen(!open)}>
				<span>{title}</span>
				{open ? (
					<FontAwesomeIcon icon={["fal", "angle-up"]} />
				) : (
					<FontAwesomeIcon icon={["fal", "angle-down"]} />
				)}
			</h2>
			{block.innerBlocks.length > 0 && (
				<AccordionItemContent className={open ? "open" : ""}>
					{block.innerBlocks.map((child, index) => (
						<BlockRenderer key={index} block={child} />
					))}
				</AccordionItemContent>
			)}
		</StyledAccordionItem>
	)
}

export default AccordionItem
