import React from "react"
import { Query } from "react-apollo"
import { setContext } from "apollo-link-context"
import {
    IntrospectionFragmentMatcher,
    ApolloClient,
    InMemoryCache,
} from "apollo-boost"
import introspectionQueryResultData from "../schemafetch/fragmentTypes.json"
import { useCookies } from "react-cookie"

import { loader } from "graphql.macro"
import { createHttpLink } from "apollo-link-http"
import BlockRenderer from "./BlockRenderer.js"
import styled from "@emotion/styled"
import Loader from "./ui/Loader.js"
const MyPagesQuery = loader("./queries/MyPagesQuery.gql")

const MyPagesWrapper = styled("div")`
    background: #f0f0f0;
    a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .heading {
        height: auto;
        padding-left: 0;
        padding-top: 0;
        margin-bottom: 0 !important;
        ${(props) => props.theme.bp.below.md} {
            margin-bottom: 0;
        }
    }
    .downloads {
        padding: 0;
        > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            > div {
                padding-left: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
                &.is-style-wide {
                    padding: 0;
                    margin: 0;
                    border-color: #f0f0f0;
                }
            }
            a {
                margin-right: 20px;
                margin-top: 0;
                ${(props) => props.theme.bp.below.md} {
                    padding: 10px 15px;
                }
            }
        }
    }
    .column {
        padding: 0 20px;
        &:last-of-type {
            > div {
                padding: 0;
                padding-bottom: 5px;
            }
        }
        &:nth-of-type(3) {
            margin-bottom: -40px;
            ${(props) => props.theme.bp.below.md} {
                margin-bottom: 0;
            }
        }
        &:nth-of-type(2) {
            > div:first-of-type {
                margin-bottom: 1.5rem;
                ${(props) => props.theme.bp.below.md} {
                    margin-bottom: 0;
                }
            }
        }
        > div {
            padding-left: 0;
            height: auto;
            padding-bottom: 5px;
            p {
                margin-bottom: 0;
            }
        }
        h1 {
            height: auto;
            font-size: 2.2rem;
        }
        h2,
        h3,
        h4 {
            font-size: 22px;
            ${(props) => props.theme.bp.below.md} {
                padding-top: 1rem;
            }
        }
        form {
            margin-bottom: -40px;
            ${(props) => props.theme.bp.below.md} {
                margin-bottom: 0;
            }
            textarea {
                border: 1px solid #fff;
                padding: 10px;
                font-weight: normal;
                font-family: "Rajdhani", sans-serif;
                margin-bottom: 0.5rem;
            }
        }
    }
`

const MyPages = () => {
    const [cookies] = useCookies(["authToken"])
    const fragmentMatcher = new IntrospectionFragmentMatcher({
        introspectionQueryResultData,
    })
    const cache = new InMemoryCache({ fragmentMatcher })

    const httpLink = createHttpLink({
        uri: "https://admin.wideco.se/graphql",
    })

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        const token = cookies.authToken
        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : "",
            },
        }
    })

    const client = new ApolloClient({
        link: authLink.concat(httpLink),
        cache: cache,
    })

    return (
        <MyPagesWrapper style={{ background: "#f0f0f0" }}>
            <Query client={client} query={MyPagesQuery}>
                {({ loading, error, data }) => {
                    if (loading) return <Loader />
                    if (error) return null

                    var blocks = []
                    if (
                        data.myPages &&
                        data.myPages.page &&
                        data.myPages.page.blocks
                    ) {
                        blocks = data.myPages.page.blocks
                    }

                    return (
                        <React.Fragment>
                            {blocks.map((block, index) => {
                                return (
                                    <BlockRenderer
                                        user={data.myPages && data.myPages.user}
                                        key={index}
                                        block={block}
                                    />
                                )
                            })}
                        </React.Fragment>
                    )
                }}
            </Query>
        </MyPagesWrapper>
    )
}
export default MyPages
