import React from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"
import MaxWidth from "../layout/MaxWidth"

export const StyledColumns = styled("div")`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: ${props => (props.attributes.align === "full" && "100%") || "1100px"};
	margin: 0 auto;
	margin-bottom: 4rem;
	> div {
		${props => props.theme.bp.above.md} {
			width: ${props => 100 / props.columns}%;
		}
	}
	&.spacing > div {
		padding: 10px;
	}
	${props => props.theme.bp.above.md} {
		margin-bottom: 6rem;
		&.contact {
			margin-bottom: 0;
		}
		&.max-height {
			overflow: hidden;
			.column {
				position: relative;
				img {
					position: absolute;
					height: auto;
				}
				.background {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 50px 30px 60px;
					> div {
						max-width: 60%;
					}
				}
			}
		}
	}

	${props => props.theme.bp.below.md} {
		flex-direction: column;
	}
`

const Columns = ({ block, user }) => {
	if (!block.attributes) return null

	return (
		<MaxWidth className={block.attributes.align === "full" && "full-width"}>
			<StyledColumns
				className={block.attributes.className + " columns"}
				attributes={block.attributes}
				columns={block.innerBlocks && block.innerBlocks.length}
			>
				{block.innerBlocks && block.innerBlocks.length > 0 && (
					<>
						{block.innerBlocks.map((child, index) => (
							<BlockRenderer key={index} user={user} block={child} />
						))}
					</>
				)}
			</StyledColumns>
		</MaxWidth>
	)
}

export default Columns
