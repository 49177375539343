import React from "react"
import { useCookies } from "react-cookie"
import { StyledButton } from "../ui/Button"
import styled from "@emotion/styled"

const LogoutWrapper = styled("div")`
	text-align: right;
`

const Logout = ({ block, user }) => {
	const [cookies, setCookie, removeCookie] = useCookies(["authToken"])

	return (
		<LogoutWrapper>
			<StyledButton
				onClick={() => {
					removeCookie("authToken")
				}}
			>
				Log out
			</StyledButton>
		</LogoutWrapper>
	)
}
export default Logout
