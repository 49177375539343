import React from "react"
import BlockRenderer from "../BlockRenderer"

const Buttons = ({ block }) => {
	if (!block.innerBlocks) return null
	return (
		<>
			{block.innerBlocks && block.innerBlocks.length > 0 && (
				<>
					{block.innerBlocks.map((child, index) => (
						<BlockRenderer key={index} block={child} />
					))}
				</>
			)}
		</>
	)
}

export default Buttons
