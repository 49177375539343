import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faFacebookF,
	faLinkedinIn,
	faTwitter,
	faYoutube
} from "@fortawesome/free-brands-svg-icons"
import CookieBar from "../../ui/CookieBar"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { client } from "../../App"

const Wrapper = styled("div")`
	background: #383838;
	color: #fff;
	padding: 5rem 2rem;
	p {
		text-align: center;
		font-size: 0.9rem;
	}
	& > p {
		font-size: 12px;
	}
	a {
		color: #fff;
	}
`
const AddressWrapper = styled("div")`
	text-align: center;
	padding-left: 4rem;
	padding-right: 4rem;
	line-height: 2rem;
	${props => props.theme.bp.below.sm} {
		padding: 0;
	}
	span {
		padding: 5px;
		display: inline-block;
		font-size: 0.9rem;
	}
	p {
		margin-bottom: 10px;
	}
	.contact-info {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 13.8rem;
		margin: 0 auto;
	}
`
const SocialMediaLinks = styled("div")`
	display: block;
	width: 100%;
	text-align: center;
	padding: 2.2rem;
	padding-top: 2.5rem;
	a {
		width: 40px;
		height: 40px;
		line-height: 37px;
		display: inline-block;
		border-radius: 40px;
		border: 1px solid #fff;
		margin: 0 7px;
	}
	svg {
		color: #fff;
		width: 18px !important;
		height: 18px;
		vertical-align: sub;
	}
`

const GET_FOOTER = gql`
	query footerWidgets($sidebarId: String!) {
		widgets(sidebarId: $sidebarId) {
			name
			widgets {
				text
				title
			}
		}
	}
`

const SocialIcons = ({ content }) => {
	const [links, setLinks] = useState([])
	const [init, setInit] = useState(false)

	const icons = {
		"facebook-icon": <FontAwesomeIcon icon={faFacebookF} />,
		"linkedin-icon": <FontAwesomeIcon icon={faLinkedinIn} />,
		"youtube-icon": <FontAwesomeIcon icon={faYoutube} />
	}

	useEffect(() => {
		if (document.getElementById("footer-social-icons")) {
			var domLinks = document
				.getElementById("footer-social-icons")
				.getElementsByTagName("a")
			var urls = []
			for (let i = 0; i < domLinks.length; i++) {
				urls.push({
					url: domLinks[i].getAttribute("href"),
					icon: domLinks[i].innerText
				})
			}
			if(urls !== links){
				setLinks(urls)
			}
			
		}
	}, [init, links])

	if (links.length < 1) {
		return (
			<>
				<div
					onLoad={() => {
						if (!init) {
							setInit(true)
						}
					}}
					id="footer-social-icons"
					dangerouslySetInnerHTML={{ __html: content }}
				/>
			</>
		)
	} else {
		return (
			<>
				{links.map(link => (
					<a key={link.url} href={link.url}>{icons[link.icon]}</a>
				))}
			</>
		)
	}
}

const Footer = () => {
	const { loading, error, data } = useQuery(GET_FOOTER, {
		variables: {
			sidebarId: "sidebar-1"
		},
		client: client
	})

	return (
		<>
			<Wrapper>
				<AddressWrapper
					dangerouslySetInnerHTML={{
						__html:
							data !== undefined &&
							data.widgets &&
							data.widgets.widgets[0] &&
							data.widgets.widgets[0].text.replace(/(?:\r\n|\r|\n)/g, "<br>")
					}}
				></AddressWrapper>
				<SocialMediaLinks>
					{
						<SocialIcons
							content={
								data !== undefined &&
								data.widgets &&
								data.widgets.widgets[1] &&
								data.widgets.widgets[1].text
							}
						/>
						/* 
					<a href="https://www.facebook.com/WiDetect/">
						<FontAwesomeIcon icon={faFacebookF} />
					</a>
					<a href="https://www.linkedin.com/company/wideco-sweden-ab">
						<FontAwesomeIcon icon={faLinkedinIn} />
					</a>

					<a href="https://www.youtube.com/channel/UCG9q5fJEJp9fNun3qMN3e3Q">
						<FontAwesomeIcon icon={faYoutube} />
					</a> */
					}
				</SocialMediaLinks>
				<p
					dangerouslySetInnerHTML={{
						__html:
							data !== undefined &&
							data.widgets &&
							data.widgets.widgets[2] &&
							data.widgets.widgets[2].text
					}}
				/>
			</Wrapper>
			<CookieBar />
		</>
	)
}

export default Footer
