import React from "react"

import styled from "@emotion/styled"

const StyledImage = styled("img")`
	display: block;
	max-width: 100%;
	width: ${props =>
		(props.attributes.width && props.attributes.width + "px") ||
		(props.attributes.align === "full" ? "100%" : "auto")};
	margin: ${props => (props.attributes.align === "center" ? "0 auto" : "0")};
	margin-bottom: ${props =>
		props.attributes.marginBottom
			? props.attributes.marginBottom + "px"
			: "auto"};
	margin-top: ${props =>
		props.attributes.marginTop ? props.attributes.marginTop + "px" : "auto"};
	.card & {
		width: calc(100% + 40px);
		max-width: 100%;
		height: auto;
	}
	${props => props.theme.bp.below.md} {
		margin-bottom: ${props =>
			props.attributes.mobileMarginBottom
				? props.attributes.mobileMarginBottom + "px"
				: "auto"};
		margin-top: ${props =>
			props.attributes.mobileMarginTop
				? props.attributes.mobileMarginTop + "px"
				: "auto"};
	}
`

const Image = ({ block }) => {
	return (
		<StyledImage attributes={block.attributes} src={block.attributes.url} />
	)
}

export default Image
