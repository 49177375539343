import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"

import styled from "@emotion/styled"
import { client } from "../App"

import { loader } from "graphql.macro"
import { StepperContext } from "./Stepper"
const PageQuery = loader("../queries/PageQuery.gql")

const FileWrapper = styled("div")`
    padding: 1rem 1.5rem !important;
    background: white;
    margin-bottom: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nameBlock {
        display: flex;
        flex-direction: column;
        font-weight: 500;
        font-size: 20px;
        line-height: 1.2em;
        font-family: Rajdhani;
        span {
            color: #004877;
            font-size: 0.85rem;
            font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        }
    }
`

export const StyledButton = styled("a")`
    border: 0.5px solid #bbbbbb;
    font-family: Rajdhani;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 1rem 2rem;
    text-transform: uppercase;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.1em;
`

const File = ({ block }) => {
    if (block.attributes === undefined) return null
    const fileInfo = block.attributes
    const fileName = fileInfo.fileName.split("|")

    return (
        <FileWrapper>
            <div className="nameBlock">
                {fileName[0]}
                <span>{fileName[1]}</span>
            </div>
            <StyledButton href={fileInfo.href}>Download</StyledButton>
        </FileWrapper>
    )
}

export default File
