import React, { useContext, useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import { LanguageContext } from "../../language/LanguageProvider";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserAlt } from "@fortawesome/pro-light-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";

import Login from "../header/Login";

import { useCookies } from "react-cookie";

import { loader } from "graphql.macro";
const HomeQuery = loader("../../queries/HomeQuery.gql");

import { faTriangle } from "@fortawesome/pro-solid-svg-icons";
library.add(faTriangle, faUser, faUserAlt);

const TopMenu = styled("div")`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  text-align: right;
  padding-right: 47px;
  background: #eee;
  display: flex;
  height: 23px;
  justify-content: flex-end;
  align-items: center;
  svg {
    max-width: 8px;
  }
  a,
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    padding-left: 15px;
    font-size: 12px;
    vertical-align: text-top;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Rajdhani", sans-serif;
    svg.fa-triangle {
      transform: rotate(-90deg);
      color: #72a09d;
      font-size: 7px;
      margin-bottom: 1px;
    }
    &.active {
      display: none;
    }
    &:last-of-type {
      padding-left: 20px;
    }
  }
  .fa-user {
    color: #005650;
  }
  .fa-user,
  .fa-user-alt {
    max-width: 12px;
  }
`;

const LanguageSelectorWrapper = styled("div")`
  position: relative;
  .activeLanguage {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
    img {
      max-width: 20px;
      height: auto;
    }
  }
  .langSelect {
    min-width: 130px;
    background: #eee;
    position: absolute;
    top: 100%;
    right: 0;
    padding: 0.2rem 0.5rem;
    color: #000;
    display: flex;
    flex-direction: column;
    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      white-space: nowrap;
      padding: 4px 0;
    }

    img {
      max-width: 20px;
      height: auto;
    }
  }
`;

export const LanguageSelector = () => {
  const { activeLanguage } = useContext(LanguageContext);
  const [open, setOpen] = useState(false);

  const langs = {
    en: {
      flag: "https://www.countryflags.io/gb/flat/24.png",
      name: "English",
    },
    fr: {
      flag: "https://www.countryflags.io/fr/flat/24.png",
      name: "Français",
    },
    sv: {
      flag: "https://www.countryflags.io/se/flat/24.png",
      name: "Svenska",
    },
  };

  return (
    <LanguageSelectorWrapper>
      <div onClick={() => setOpen(!open)} className="activeLanguage">
        <img
          src={langs[activeLanguage].flag}
          alt={langs[activeLanguage].name}
        />
      </div>
      {open && (
        <div className="langSelect">
          <a href="/en">
            <img src={langs["en"].flag} alt={langs["en"].name} />
            <span>{langs["en"].name}</span>
          </a>
          <a href="/fr">
            <img src={langs["fr"].flag} alt={langs["fr"].name} />
            <span>{langs["fr"].name}</span>
          </a>
          <a href="/sv">
            <img src={langs["sv"].flag} alt={langs["sv"].name} />
            <span>{langs["sv"].name}</span>
          </a>
        </div>
      )}
    </LanguageSelectorWrapper>
  );
};

const TopHeader = ({ client }) => {
  const [login, setLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [cookies] = useCookies(["authToken"]);
  var authToken = cookies.authToken;
  const { activeLanguage } = useContext(LanguageContext);

  useEffect(() => {
    if (cookies.authToken && cookies.authToken.length > -1 && !isLoggedIn) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, [authToken]);

  return (
    <TopMenu>
      <LanguageSelector />
      <Link to={`/${activeLanguage}/my-pages`} onClick={() => setLogin(!login)}>
        <FontAwesomeIcon icon={isLoggedIn ? faUser : faUserAlt} />
      </Link>
      {login && !isLoggedIn && <Login setLogin={setLogin} />}
    </TopMenu>
  );
};

export default TopHeader;
