import React, { useContext, useRef } from "react"
import styled from "@emotion/styled"
import { StyledButton } from "../../ui/Button"

import { ApolloContext } from "react-apollo"
import { loader } from "graphql.macro"
const TokenLogin = loader("../../mutations/TokenLogin.gql")

import { Link } from "react-router-dom"

import { useMutation } from "@apollo/react-hooks"

import { useCookies } from "react-cookie"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons"
import { CoverStyles } from "../../styles/Cover"

library.add(faSpinnerThird)

const LoginForm = styled("form")`
	position: fixed;
	width: 490px;
	max-width: 90%;
	left: 50%;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
	background: #eee;
	flex-direction: column;
	color: #000;
	text-align: left;
	padding: 40px;
	padding-top: 35px;
	input {
		text-align: left;
		margin-bottom: 1rem;
		width: 100%;
		padding: 5px;
	}
	label {
		font-family: "Rajdhani", sans-serif;
		display: block;
		width: 100%;
	}
	a {
		max-width: 130px;
		text-align: center;
		padding: 15px;
		width: 100%;
		float: right;
	}
`

const LoginButton = styled(StyledButton)`
	color: #fff !important;
	&:hover {
		color: #000 !important;
	}
`

const Cover = styled(Link)`
	${CoverStyles};
	background: rgba(0, 0, 0, 0.6);
`

const Login = ({ setLogin }) => {
	const apollo = useContext(ApolloContext)

	const [doLogin, { data, loading, error }] = useMutation(TokenLogin, {
		client: apollo.client
	})
	const username = useRef()
	const password = useRef()
	const [cookies, setCookie] = useCookies(["authToken"])
	const loginHandler = () => {
		const clientMutationId = Date.now().toString()

		doLogin({
			variables: {
				clientMutationId: clientMutationId,
				username: username.current.value,
				password: password.current.value
			}
		})
	}

	const handleKeyDown = e => {
		if (e.key === "Enter") {
			loginHandler()
		}
	}

	if (data) {
		setCookie("authToken", data.login.authToken)
	}
	return (
		<>
			<Cover
				to={"/"}
				onClick={() => {
					setLogin(false)
				}}
			/>
			<LoginForm onSubmit={loginHandler}>
				<label for="username">Username or email</label>
				<input
					onKeyDown={handleKeyDown}
					ref={username}
					id="username"
					name="username"
				/>
				<label for="password">Password</label>
				<input
					onKeyDown={handleKeyDown}
					ref={password}
					id="password"
					name="password"
					type="password"
				/>
				<LoginButton onClick={loginHandler}>
					{loading ? <FontAwesomeIcon icon={faSpinnerThird} /> : "Login"}
				</LoginButton>
			</LoginForm>
		</>
	)
}

export default Login
