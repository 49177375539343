import React from "react"
import { Query } from "react-apollo"
import { loader } from "graphql.macro"
const PageQuery = loader("./queries/PageQuery.gql")
import BlockRenderer from "./BlockRenderer"
import styled from "@emotion/styled"
import { contactPostsStyles } from "./styles/Contact"
import Loader from "./ui/Loader"
import NotFound from "./NotFound"

const PageContainer = styled("div")`
    background: #f0f0f0;
    padding-top: 85px;
    position: relative;
    display: block;

    &:not(.business-cases-2) {
        //padding-bottom: 80px;
    }
    &.business-cases-2,
    &.blog-2 {
        padding-bottom: 0;
        .wide {
            margin-bottom: 0;
        }
    }
    &.contact-2 {
        padding-bottom: 0;
        > div:first-child {
            width: 100%;
            max-width: 100%;
        }
    }
    &:not(.business-cases-2) > *:last-child {
        //padding-bottom: 8rem;
    }
    ${props => props.theme.bp.below.md} {
        padding-top: 60px;
        padding-bottom: 40px;
    }
    ${contactPostsStyles}
    &.contact {
        .contact {
            margin-bottom: 0;
            padding-bottom: 0 !important;
            &article {
                padding-bottom: 5px !important;
            }
        }
        & > div {
            max-width: 100%;
        }
        .background h2 {
            padding-top: 3.5rem;
            padding-bottom: 0;
        }
        .background.contact-text h2 {
            padding-top: 0px;
            padding-bottom: 1rem;
        }
    }
    &.blog:first-of-type {
        max-width: 100%;
        display: block;
        column-count: 1;
    }
    // CARD H1
    .card.wide {
        text-align: center;
        ${props => props.theme.bp.below.md} {
        }
        h1 {
            margin-bottom: 0;
            ${props => props.theme.bp.below.md} {
                font-size: 1.7rem;
                line-height: 1.7rem;
            }
        }
        .heading:first-child + .heading + div,
        .heading:first-child + div:not(.heading) {
            margin-top: 40px;
            ${props => props.theme.bp.below.md} {
                margin-top: 20px;
            }
        }
        .heading h2 {
            font-size: 1.5rem;
            padding-top: 5px;
            ${props => props.theme.bp.below.md} {
                font-size: 1.2rem;
                line-height: 1.2rem;
                padding-top: 15px;
            }
            &.has-light-gray-color {
                color: #969696;
            }
        }
        & > div {
            & > div {
                padding-bottom: 0;
                padding-top: 0;
            }
        }
    }

    // PRODUCT PAGE
    &.product-page {
        .card.wide {
            padding-bottom: 65px !important;
            padding-top: 65px;
            & > div {
                padding-bottom: 0;
                max-width: 650px;
                & > div:first-of-type {
                    padding: 0;
                }
                & > div:nth-of-type(2) {
                    padding: 0;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }
                & > div:last-of-type {
                    padding-bottom: 0;
                }
                h1 {
                    margin-bottom: 0;
                    letter-spacing: 0.5px;
                    padding-bottom: 0;
                }
                p {
                }
            }
            ${props => props.theme.bp.below.md} {
                padding: 45px 20px !important;
                margin-bottom: 2.5rem;
                & > div {
                    & > div p {
                        font-size: 0.9rem;
                        &.wp-block-subhead {
                            font-size: 1.1rem;
                        }
                    }
                    h1 {
                        font-size: 2.2rem;
                        padding-top: 0;
                        padding-bottom: 3px;
                    }
                }
            }
        }

        .card.narrow.accordion-card {
            padding-bottom: 80px !important;
            padding-top: 80px;
            ${props => props.theme.bp.below.md} {
                padding: 30px !important;
                .AccordionItem {
                    h2 {
                        font-size: 1rem;
                        line-height: 1.2rem;
                        position: relative;
                        svg {
                            position: absolute;
                            right: 5px;
                            top: 50%;
                            margin-right: 0px;
                            margin-top: 0;
                            transform: translateY(-50%);
                        }
                    }
                }
            }
            h2 {
                padding: 0;
                font-weight: normal;
                ${props => props.theme.bp.below.md} {
                    font-size: 1.3rem;
                    line-height: 1.7rem;
                }
            }
            & > div {
                & > div {
                    padding-bottom: 2rem;
                }
            }
        }

        .wp-block-subhead:first-of-type {
            font-size: 1.4rem;
            letter-spacing: 0.5px;
            color: #000;
            margin-top: 0;
            padding-top: 0;
        }
        .column {
            img {
                width: 100%;
                display: block;
            }
            .background {
                display: flex;
                align-items: center;
                padding: 80px 30px;
                ${props => props.theme.bp.below.md} {
                    padding: 30px;
                }
                & > div {
                    & > div {
                        ${props => props.theme.bp.below.md} {
                            padding: 5px;
                        }
                    }
                }
            }
            h3 {
                font-size: 2rem;
                line-height: 2rem;
                padding-bottom: 1rem;
                ${props => props.theme.bp.below.md} {
                    font-size: 1.7rem;
                    line-height: 1.7rem;
                }
            }
        }
        p {
            font-size: 1rem;
            ${props => props.theme.bp.below.md} {
                font-size: 0.9rem;
            }
        }
        h1 {
            margin-bottom: 0;
            font-size: 2.8rem;
            line-height: 2.8rem;
            font-weight: normal;
            padding-bottom: 100px;
            ${props => props.theme.bp.below.md} {
                padding-top: 60px;
                padding-bottom: 60px;
                font-size: 1.6rem;
                line-height: 1.8rem;
            }
        }
    }
`

const Page = ({ match }) => {
    let path = match.url.replace(`/${match.params.lang}`, "").slice(1)
    if (path.substring(path.length - 1) == "/") {
        path = path.slice(0, -1)
    }
    return (
        <Query
            query={PageQuery}
            variables={{
                uri: path
            }}>
            {({ loading, error, data }) => {
                if (loading) if (loading) return <Loader />
                if (error) return <NotFound />

                var blocks = []

                if (!data) return null
                if (data.pageBy && data.pageBy.blocks) {
                    blocks = data.pageBy.blocks
                }

                var content = data.pageBy.content

                const depth = data.pageBy.uri.split("/").length
                return (
                    <>
                        <PageContainer
                            className={
                                "page " +
                                data.pageBy.slug +
                                " " +
                                (data.pageBy.uri.indexOf(
                                    "monitoring-solutions"
                                ) > -1 &&
                                    depth > 2 &&
                                    "product-page")
                            }>
                            {blocks.length > 0 ? (
                                <React.Fragment>
                                    {blocks.map((block, index) => {
                                        return <BlockRenderer key={index} block={block} />
                                    })}
                                </React.Fragment>
                            ) : (
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: content
                                    }}
                                />
                            )}
                        </PageContainer>
                    </>
                )
            }}
        </Query>
    )
}

export default Page
