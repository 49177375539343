import React, { useContext } from "react";
import { Post } from "./Components";
import { StyledButton } from "../ui/Button";
import { dateFormatter } from "../utils/dateFormatter";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import { LanguageContext } from "../language/LanguageProvider";

const BlogPost = ({ post, parameters, popup, setPopup, match }) => {
  const { activeLanguage, blogLink, refLink } = useContext(LanguageContext);

  const languageButtonTextHandler = () => {
    switch (activeLanguage) {
      case "fr":
        return "En savoir plus";
      case "sv":
        return "Läs mer";
      default:
        return "Read more";
    }
  };

  return (
    <Post className="blog-posts">
      {post.featuredImage && post.featuredImage.sourceUrl && (
        <Link onClick={() => setPopup(post.id)} to={!setPopup && post.slug}>
          <img src={post.featuredImage.sourceUrl} />
        </Link>
      )}
      <div className="post-content">
        <time>{dateFormatter(post.date, match.params.lang)}</time>
        <Link onClick={() => setPopup(post.id)} to={!setPopup && post.slug}>
          <h3 dangerouslySetInnerHTML={{ __html: post.title }}></h3>
        </Link>
      </div>
      {parameters !== undefined ? (
        <StyledButton
          onClick={() => setPopup(post.id)}
          to={!setPopup && post.slug}
        >
          {languageButtonTextHandler()}
        </StyledButton>
      ) : (
        <StyledButton to={blogLink + "?open=" + post.slug}>
          {languageButtonTextHandler()}
        </StyledButton>
      )}
    </Post>
  );
};
export default withRouter(BlogPost);
