import React from "react"
import styled from "@emotion/styled"

import BlockRenderer from "../BlockRenderer"

const AccordionWrapper = styled("div")`
	border-top: 1px solid #b9b9b9;
`

const Accordion = ({ block }) => {
	return (
		<AccordionWrapper>
			{block.innerBlocks &&
				block.innerBlocks.length > 0 &&
				block.innerBlocks.map((child, index) => (
					<BlockRenderer key={index} block={child} />
				))}
		</AccordionWrapper>
	)
}

export default Accordion
