import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./Home";
import Page from "./Page";
import "normalize.css";
import "./App.css";

import "cross-fetch/polyfill";
import { ApolloProvider } from "react-apollo";
import ApolloClient from "apollo-client";
import { IntrospectionFragmentMatcher } from "apollo-cache-inmemory";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createPersistedQueryLink } from "apollo-link-persisted-queries";
import { createHttpLink } from "apollo-link-http";

import { CookiesProvider } from "react-cookie";

import introspectionQueryResultData from "../schemafetch/fragmentTypes.json";
import Header from "./layout/header/Header";
import Footer from "./layout/footer/Footer";
import Theme from "./Theme";
import Blog from "./Blog";
import Post from "./Post";
import LanguageProvider from "./language/LanguageProvider";
import References from "./References";
import MyPages from "./MyPages";
import Reference from "./Reference";
import ScrollToTopControlller from "./ScrollToTopController";
import { HeadProvider } from "react-head";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const cache = new InMemoryCache({ fragmentMatcher });

const graph_uri = "https://admin.wideco.se/graphql";

const link = createPersistedQueryLink().concat(
  createHttpLink({ uri: graph_uri })
);

export const client = new ApolloClient({
  ssrMode: true,
  cache,
  link: link,
});

const headTags = [];

const LangRoutes = ({ match }) => {
  return (
    <HeadProvider headTags={headTags}>
      <CookiesProvider>
        <ApolloProvider client={client}>
          <LanguageProvider {...match.params}>
            <Theme>
              <Header client={client} />
              <ScrollToTopControlller />
              <Switch>
                <Route exact path="/:lang" component={Home} />

                <Route exact path="/:lang/my-pages" component={MyPages} />

                <Route
                  path={["/:lang/nyheter", "/:lang/news-fr", "/:lang/blog-2"]}
                  component={Blog}
                />

                <Route
                  path={[
                    "/:lang/nyheter/:path",
                    "/:lang/news-fr/:path",
                    "/:lang/blog-2/:path",
                  ]}
                  component={Post}
                />

                <Route
                  path={[
                    "/:lang/wideco-3/kund-case",
                    "/:lang/wideco-3/business-cases-2",
                    "/:lang/references",
                  ]}
                  component={References}
                />

                <Route
                  path={[
                    "/:lang/:path/:subpath/:thirdlevel/:fourthlevel/:fifthlevel",
                    "/:lang/:path/:subpath/:thirdlevel/:fourthlevel",
                    "/:lang/:path/:subpath/:thirdlevel",
                    "/:lang/:path/:subpath",
                    "/:lang/:path",
                  ]}
                  component={Page}
                />
              </Switch>
              <Footer />
            </Theme>
          </LanguageProvider>
        </ApolloProvider>
      </CookiesProvider>
    </HeadProvider>
  );
};

const App = () => (
  <Switch>
    <Route path="/:lang" component={LangRoutes} />
    <Redirect exact from="/" to="/sv" />
  </Switch>
);

export default App;
