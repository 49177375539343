import React from "react"
import Welcome from "../shortcodes/my-pages/Welcome"
import Details from "../shortcodes/my-pages/Details"
import Logout from "../shortcodes/Logout"
import ContactForm from "../shortcodes/my-pages/ContactForm"

const ShortCodeRenderer = ({ block, user }) => {
	if (!block) return null
	if (block && !block.attributes) return null
	const code = block.attributes.text
	switch (code) {
		case "my-pages-welcome":
			return <Welcome block={block} user={user} />
			break
		case "my-pages-details":
			return <Details block={block} user={user} />
			break
		case "logout":
			return <Logout block={block} user={user} />
			break
		case "contact-form":
			return <ContactForm block={block} user={user} />
			break
		default:
			return null
	}
}
export default ShortCodeRenderer
