import React, { useEffect } from "react"
import { withRouter } from "react-router-dom"

// Component that attaches scroll to top hanler on router change
// renders nothing, just attaches side effects
const ScrollToTopControlller = ({ children, history }) => {
	// this assumes that current router state is accessed via hook
	// but it does not matter, pathname and search (or that ever) may come from props, context, etc.
	const {
		location: { pathname }
	} = history

	// just run the effect on pathname and/or search change
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [pathname])

	// renders nothing, since nothing is needed
	return <></>
}
export default withRouter(ScrollToTopControlller)
