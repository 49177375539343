import React from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"
//import { StyledBackground } from "./Background"

const StyledSlide = styled("div")`
	padding: 10px;
	&:first-of-type {
		padding-left: 0;
	}
	&:last-of-type {
		padding-right: 0;
	}
	.background {
		margin: -120px auto 0;
		position: relative;
		z-index: 2;
		width: 80%;
		padding: 20px;
	}
	> img {
		max-width: none;
		${props => props.theme.bp.below.md} {
			width: 340px;
		}
	}
	${props => props.theme.bp.above.md} {
		padding: 0;
		margin: 0 1.2rem 0 1.2rem;
		width: 100%;
		&:first-of-type {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
		> img {
			width: 100%;
		}
		.background {
			margin-top: -170px;
			width: 80%;
			padding: 50px 30px;
			h3 {
				font-size: 1.7rem;
			}
			p {
				font-size: 1rem;
				margin-left: -10px;
			}
			.heading {
				margin-left: -10px;
			}
			div {
				padding-bottom: 0;
			}
		}
	}
`

const Slide = ({ block }) => {
	return (
		<StyledSlide className="slide">
			{block.innerBlocks.length > 0 && (
				<React.Fragment>
					{block.innerBlocks.map((child, index) => (
						<BlockRenderer key={index} block={child} />
					))}
				</React.Fragment>
			)}
		</StyledSlide>
	)
}

export default Slide
