import React from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"


export const StyledSpacer = styled("div")`

	height: ${props => props.attributes.height}px;

`

const InnerBlocks = styled("div")``

const Spacer = ({ block }) => {

	return (
		<StyledSpacer attributes={block.attributes} />
	)
}

export default Spacer
