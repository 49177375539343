import React from "react"
import styled from "@emotion/styled"

import { useCookies } from "react-cookie"
import MaxWidth from "../layout/MaxWidth"

const Bar = styled("div")`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: rgba(0, 0, 0, 0.4);
	color: #fff;
	padding: 5px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 9999;
	${props => props.theme.bp.below.md} {
		span {
			width: 50%;
			font-size: 12px;
		}
	}
`

const StyledMaxWidth = styled(MaxWidth)`
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	max-width: 1100px;
`

const AcceptButton = styled("button")`
	padding: 6px 10px;
	background: transparent;

	border: 1px solid #fff;
	background: #fff;
	color: #000;
	cursor: pointer;
	&:hover {
		background: #000;
		color: #fff;
	}
`

const CookieBar = ({ block }) => {
	const [cookies, setCookie] = useCookies(["cookiesAccepted"])
	if (cookies.cookiesAccepted) return null
	return (
		<Bar>
			<StyledMaxWidth>
				<span>This website uses cookies to enhance the user experience</span>
				<AcceptButton
					onClick={() => {
						let d = new Date()
						d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000)
						setCookie("cookiesAccepted", true, {
							expires: d
						})
					}}
				>
					I understand
				</AcceptButton>
			</StyledMaxWidth>
		</Bar>
	)
}

export default CookieBar
