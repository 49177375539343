import React from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"
import { cardStyles } from "../styles/Card"
import { Title } from "react-head"
import { decodeHtml } from "../utils/decodeHtml"

export const StyledCard = styled("div")`
	${cardStyles};
	background-color: ${props => props.background};

	.columns {
		margin-bottom: 0;
	}
`

const InnerBlocks = styled("div")``

const Card = ({ block }) => {
	return (
		<StyledCard
			className={
				"card " +
				(block.attributes && block.attributes.narrow === true
					? "narrow"
					: "wide") +
				" " +
				(block.attributes && block.attributes.className)
			}
			background={block.attributes && block.attributes.backgroundColor}
		>
			{block.innerBlocks && block.innerBlocks.length > 0 && (
				<InnerBlocks>
					{block.innerBlocks.map((child, index) => (
						<>
							{child.originalContent &&
								child.originalContent.indexOf("h1") > -1 && (
									<Title key={`title-${index}`}>{decodeHtml(child.originalContent)}</Title>
								)}
							<BlockRenderer key={index} block={child} />
						</>
					))}
				</InnerBlocks>
			)}
		</StyledCard>
	)
}

export default Card
