import React from "react"
import styled from "@emotion/styled"

const Wrapper = styled("div")`
	display: flex;
	flex-direction: column;
	justify-content: stretch;
	align-items: stretch;
	margin: 0 auto;
	position: relative;
	flex: 1 1 auto;
	width: 100%;
	max-width: 1400px;
	&.full-width {
		max-width: 100%;
	}
`

const MaxWidth = ({ className = "", children }) => {
	return <Wrapper className={className}>{children}</Wrapper>
}

export default MaxWidth
