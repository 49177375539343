import React from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"

export const StyledSeparator = styled("div")`
	flex: 1 1 auto;
	width: 100%;
	border-bottom: 1px solid #383838;
	margin: 10px 0;
`

const Separator = ({ block }) => {
	return <StyledSeparator className={block.attributes.className} />
}

export default Separator
