import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"

import styled from "@emotion/styled"
import { client } from "../App"

import { loader } from "graphql.macro"
import { StepperContext } from "./Stepper"
const PageQuery = loader("../queries/PageQuery.gql")

export const StyledButton = styled(Link)`
	background: linear-gradient(
			216.41deg,
			rgba(255, 255, 255, 0.5) 1.1%,
			rgba(255, 255, 255, 0) 89.62%
		),
		#005650;
	text-decoration: none;
	color: #fff;
	padding: 14px 40px;
	display: inline-block;
	text-transform: uppercase;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: 0.1em;
	font-family: "Rajdhani", sans-serif;
	outline: transparent solid 1px;
	position: relative;
	&:hover {
		background: transparent;
		color: #000;
		outline: #afafaf solid 1px;
	}
`

const Button = ({ block }) => {
	if (block.attributes === undefined) return null

	var url = block.attributes.url
		? block.attributes.url.replace("https://admin.wideco.se", "")
		: ""
	const context = useContext(StepperContext)

	const [open, setOpen] = useState(false)
	return (
		<div
			className={"button " + (open ? "open" : "closed")}
			style={{ textAlign: block.attributes.align }}
		>
			<StyledButton
				onClick={e => {
					if (url.indexOf("wp-content") > -1) {
						e.preventDefault()
						window.location.href = "https://admin.wideco.se/" + url
					}
					if (context && context.hasStepper) {
						e.preventDefault()
						setOpen(!open)
					}
				}}
				onMouseOver={() => {
					if (url.indexOf("en/") === 0) {
						url = url.replace("en/", "")
					}

					client.query({
						query: PageQuery,
						variables: {
							uri: url
						}
					})
				}}
				to={url}
			>
				{block.attributes.text}
			</StyledButton>
		</div>
	)
}

export default Button
