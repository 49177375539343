import React from "react"

import { Query } from "react-apollo"

import { loader } from "graphql.macro"
const PostQuery = loader("./queries/PostQuery.gql")

import BlockRenderer from "./BlockRenderer"
import styled from "@emotion/styled"
import { dateFormatter } from "./utils/dateFormatter"
import Page from "./Page"
import { cardStyles } from "./styles/Card"

const PostContainer = styled("div")`
	background: #f0f0f0;
	min-height: 100vh;
	.page {
		min-height: 0;
		.card {
			display: none;
		}
	}
	article {
		margin-bottom: 0;
		.time {
			font-size: 1.2rem;
		}
		h1 {
			font-size: 1.9rem;
		}
	}
`
const PostWrapper = styled("article")`
	${cardStyles}
	background:#FFF;
	time {
		display: block;
		color: rgba(6, 108, 97, 0.5);
		font-family: Rajdhani;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 18px;
		text-align: center;
		color: rgba(6, 108, 97, 0.5);
	}
	h1 {
		font-family: Rajdhani;
		font-style: normal;
		font-weight: 500;
		font-size: 26px;
		line-height: 1.6;
		margin: 0.6em 0;
		text-align: center;
	}
`

const Post = props => {
	const {
		match: {
			params: { path }
		}
	} = props

	var uri = path
	var blogUri =
		typeof window !== "undefined" && window.location.pathname.split(uri)[0]

	return (
		<Query query={PostQuery} variables={{ uri }}>
			{({ loading, error, data }) => {
				if (loading) return null
				if (error) return null

				var blocks = []
				if (data.postBy && data.postBy.blocks) {
					blocks = data.postBy.blocks
				}
				var content = data.postBy.content
				if (!data.postBy) {
					return null
				}

				return (
					<PostContainer className="post">
						<Page location={{ pathname: blogUri }} />
						<PostWrapper>
							<time>{dateFormatter(data.postBy.date)}</time>
							<h1 dangerouslySetInnerHTML={{ __html: data.postBy.title }}></h1>
							{blocks.length > 0 ? (
								<React.Fragment>
									{blocks.map((block, index) => {
										return <BlockRenderer block={block} />
									})}
								</React.Fragment>
							) : (
								<div dangerouslySetInnerHTML={{ __html: content }} />
							)}
						</PostWrapper>
					</PostContainer>
				)
			}}
		</Query>
	)
}

export default Post
