import React, { useContext } from "react"
import { Post } from "./Components"
import { StyledButton } from "../ui/Button"
import { LanguageContext } from "../language/LanguageProvider"

const PagePost = ({ post, parameters }) => {
	const { activeLanguage } = useContext(LanguageContext)
	const languageButtonTextHandler = () => {
		switch (activeLanguage) {
			case "fr":
				return "En savoir plus";
			case "sv":
				return "Läs mer";
			default:
				return "Read more";
		}
	}

	return (
		<Post>
			{post.featuredImage && post.featuredImage.sourceUrl && (
				<img src={post.featuredImage.sourceUrl} />
			)}
			<h3 dangerouslySetInnerHTML={{ __html: post.title }}></h3>
			<div
				dangerouslySetInnerHTML={{
					__html: post.excerpt
				}}
			/>
			{parameters !== undefined ? (
				<StyledButton to={`/${activeLanguage}/${post.uri}`}>
					{languageButtonTextHandler()}
				</StyledButton>
			) : (
				<StyledButton to={`/${activeLanguage}/${post.uri}`}>
					{languageButtonTextHandler()}
				</StyledButton>
			)}
		</Post>
	)
}

export default PagePost
