import React from "react"
import styled from "@emotion/styled"

const StyledGallery = styled("div")`
	ul {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding-left: 0;
		margin-left: 0;
		justify-content: center;
		align-items: center;
		margin-top: 3rem;
		li {
			padding: 15px;
			width: ${props => 100 / props.attributes.columns}%;
			text-align: center;
			${props => props.theme.bp.below.md} {
				width: 50%;
			}
			img {
				max-height: 32px;
			}
		}
	}
`

const Gallery = ({ block }) => {
	return (
		<StyledGallery
			attributes={block.attributes}
			dangerouslySetInnerHTML={{ __html: block.originalContent }}
		/>
	)
}

export default Gallery
