import React, { useState, useContext, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import { Below, Above } from "../../utils/Helpers"
import styled from "@emotion/styled"

import { useCookies } from "react-cookie"

import { Query } from "react-apollo"
import { loader } from "graphql.macro"
const MenuQuery = loader("../../queries/MenuQuery.gql")

const PageQuery = loader("../../queries/PageQuery.gql")

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

import { LanguageContext } from "../../language/LanguageProvider"
import Login from "./Login"
import { client as testClient } from "../../App"
import gql from "graphql-tag"
import { LanguageSelector } from "../topHeader/TopHeader"

const StyledMenu = styled("nav")`
    font-family: ${props => props.theme.fonts.headings};
    ${props => props.theme.bp.below.md} {
        background: #696868;
        position: absolute;
        left: 0;
        top: 0;
        width: 85vw;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        padding: 20px;
        display: ${props => (props.menuOpen ? "flex" : "none")};
        z-index: 9999;
        height: 1vh;
        overflow: scroll;
    }
    ${props => props.theme.bp.above.md} {
        &.sub-open:after {
            position: absolute;
            top: 100%;
            content: "";
            right: 0;
            width: 100%;
            height: 100px;
            z-index: -1;
        }
    }
    svg {
        font-size: 28px;
        margin-bottom: 2rem;
    }
`
const MenuList = styled("ul")`
    list-style: none;
    padding-left: 0;
    ${props => props.theme.bp.above.md} {
        padding-left: 0.7rem;
    }
`
const StyledMenuItem = styled("li")`
    font-size: 16px;
    line-height: 26px;
    font-weight: bold;
    ${props => props.theme.bp.above.md} {
        display: inline-block;
        line-height: 55px;
        &:hover {
            & > span,
            a {
                position: relative;
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 5px;
                    background: #6ec6c0;
                    left: 0;
                    right: 0;
                    z-index: 99;
                }
            }
        }
    }
    a,
    span {
        margin-bottom: 0.9rem;
        display: inline-block;
        cursor: pointer;
        font-weight: 500;
        ${props => props.theme.bp.above.md} {
            margin-bottom: 0rem;
            padding-top: 10px;
            padding: 0 20px;
            vertical-align: text-top;
            font-weight: normal;
        }
    }
    a {
        padding-top: 0;
    }
    &.open > span {
        border-bottom: 3px solid #6ec6c0;
        margin-bottom: 0.5rem;
        ${props => props.theme.bp.above.md} {
            margin-bottom: 0rem;
            border-bottom: 0;
            position: relative;
            &:after {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 5px;
                background: #6ec6c0;
                left: 0;
                right: 0;
                z-index: 99;
            }
        }
    }
    .sub & {
        font-weight: 100;
    }
    .sub {
        padding-left: 2.2rem;
        a {
            font-weight: normal;
        }
        ${props => props.theme.bp.above.md} {
            position: absolute;
            left: 0;
            width: 100%;
            background: #88898d;
            text-align: right;
            padding: 0;
            padding-right: 25px;
            height: 42px;
            li {
                line-height: initial;
                vertical-align: middle;
                height: 30px;
                & > span,
                a {
                    &:after {
                        display: none;
                    }
                }
                &:hover {
                    & > span,
                    a {
                        position: relative;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.active {
        a {
            border-bottom: 3px solid #6ec6c0;
        }
    }
`

const ShortCuts = styled("nav")`
    ul {
        padding-top: 1.5rem;
        li {
            font-size: 16px;
        }
    }
`

const MenuLink = styled(Link)`
    color: #fff;
`

const LanguageSelectorWrapper = styled('div')`
    margin-bottom: 12px!important;
    .activeLanguage {
        margin-left: 0!important;
    }

    .langSelect{
         left:27px!important;
         flex-direction:row!important;
         background: transparent!important;
    }

    > div a {
        margin-right: 10px!important;
        align-items: center!important;
        color: #fff!important;
        img{
            margin-right: 3px!important;
        }

        span {
            padding-top: 2px!important;
            line-height:20px!important;
        }
    }
`;

const SubMenu = props => {
    const { childItems, setOpenId, openId, openMenu, client } = props
    if (childItems.nodes.length > 0) {
        return (
            <MenuList className="sub">
                {childItems.nodes.map((item, index) => {
                    var uri = item.url.replace("https://admin.wideco.se", "")
                    if (uri.indexOf("/en/") === 0) {
                        uri = uri.replace("/en/", "")
                    }
                    // Dödade denna, den åt upp servern
                    // client.query({
                    //     query: PageQuery,
                    //     variables: {
                    //         uri: uri
                    //     }
                    // })
                    return (
                        <MenuItem
                            key={index}
                            item={item}
                            index={index}
                            setOpenId={setOpenId}
                            openId={openId}
                            openMenu={openMenu}
                            client={client}
                        />
                    )
                })}
            </MenuList>
        )
    }
    return null
}

const MenuItem = props => {
    const { item, setOpenId, openId, index, openMenu, topLevel, client } = props
    if (item.childItems && item.childItems.nodes.length < 1) {
        var uri = item.url.replace("https://admin.wideco.se", "")
        if (uri.indexOf("/en/") === 0) {
            uri = uri.replace("/en/", "")
        }

        // client.query({
        //     query: PageQuery,
        //     variables: {
        //         uri: uri
        //     }
        // })
    }
    return (
        <StyledMenuItem
            onMouseOver={() => {
                setOpenId(item.id)
            }}
            className={openId === item.id && "open"}
            key={index}>
            {item.childItems && item.childItems.nodes.length > 0 ? (
                <span dangerouslySetInnerHTML={{ __html: item.label }}></span>
            ) : (
                <MenuLink
                    // onMouseOver={() => {
                    //     var uri = item.url.replace(
                    //         "https://admin.wideco.se",
                    //         ""
                    //     )
                    //     if (uri.indexOf("/en/") === 0) {
                    //         uri = uri.replace("/en/", "")
                    //     }

                    //     client.query({
                    //         query: PageQuery,
                    //         variables: {
                    //             uri: uri
                    //         }
                    //     })
                    // }}
                    dangerouslySetInnerHTML={{ __html: item.label }}
                    onClick={() => {
                        openMenu(false)
                        setOpenId(null)
                    }}
                    to={item.url.replace(
                        "https://admin.wideco.se",
                        ""
                    )}></MenuLink>
            )}
            {openId === item.id && (
                <SubMenu
                    client={client}
                    setOpenId={setOpenId}
                    openId={openId}
                    childItems={item.childItems}
                    openMenu={openMenu}
                />
            )}
        </StyledMenuItem>
    )
}

function useOutsideAlerter(ref, setOpenId) {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            setOpenId(null)
        }
    }

    useEffect(() => {
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside)
        }
    })
}

const Menu = props => {
    const { activeLanguage } = useContext(LanguageContext)
    let id = "TWVudTo3MQ=="
    if (activeLanguage === "en") {
        id = "TWVudTo3Mg=="
    }
    if (activeLanguage === "fr") {
        id = "TWVudToxNzA=="
    }
    const {
        openMenu,
        menuOpen,
        searchOpen,
        openSearch,
        openId,
        setOpenId
    } = props

    const [login, setLogin] = useState(false)
    const [cookies] = useCookies(["authToken"])
    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, setOpenId)
    return (
        <div ref={wrapperRef}>
            <Query query={MenuQuery} variables={{ id }}>
                {({ loading, error, data, client }) => {
                    if (loading) return null
                    if (error) return null

                    return (
                        <StyledMenu
                            onMouseLeave={() => setOpenId(null)}
                            menuOpen={menuOpen}
                            className={openId && "sub-open"}>
                            <Below size="md">
                                <FontAwesomeIcon
                                    onClick={() => openMenu(false)}
                                    icon={faTimes}
                                />
                            </Below>
                            <MenuList>
                                {data.menu.menuItems.nodes.map(
                                    (item, index) => {
                                        return (
                                            <MenuItem
                                                client={client}
                                                topLevel={true}
                                                item={item}
                                                key={index}
                                                setOpenId={setOpenId}
                                                openId={openId}
                                                openMenu={openMenu}
                                            />
                                        )
                                    }
                                )}
                                <Above size="md"></Above>
                            </MenuList>

                            <Below size="md">
                                <ShortCuts>
                                    <MenuList>
                                        <StyledMenuItem>
                                            
                                            <MenuLink
                                                to={`/${activeLanguage}/my-pages`}
                                                onClick={() => {
                                                    setLogin(!login)
                                                    openMenu(false)
                                                }}>
                                                Login
                                            </MenuLink>
                                        </StyledMenuItem>
                                        <LanguageSelectorWrapper>
                                                <LanguageSelector/>
                                        </LanguageSelectorWrapper>
                                        {/* <>
                                            {languages.map((lang, index) => {
                                                return (
                                                    <StyledMenuItem
                                                        key={index}
                                                        className={
                                                            activeLanguage.key ===
                                                            lang.key
                                                                ? "active"
                                                                : ""
                                                        }>
                                                        <MenuLink
                                                            onClick={() =>
                                                                openMenu(false)
                                                            }
                                                            to={lang.url}>
                                                            {lang.name}
                                                        </MenuLink>
                                                    </StyledMenuItem>
                                                )
                                            })}
                                        </> */}
                                    </MenuList>
                                </ShortCuts>
                            </Below>
                        </StyledMenu>
                    )
                }}
            </Query>
            {login && !cookies.authToken && <Login />}
        </div>
    )
}

export default Menu
