import styled from "@emotion/styled"
import css from "@emotion/css"

export const CoverStyles = () => css`
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
`
