import React from "react"
import { Post } from "./Components"
const Accessory = ({ post }) => {
	return (
		<Post className="accessory">
			{post.featuredImage && post.featuredImage.sourceUrl && (
				<img src={post.featuredImage.sourceUrl} />
			)}
			<h3 dangerouslySetInnerHTML={{ __html: post.title }}></h3>
			<div dangerouslySetInnerHTML={{ __html: post.content }} />
		</Post>
	)
}
export default Accessory
