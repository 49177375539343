import React from "react"

import styled from "@emotion/styled"

const NotFoundWrapper = styled("div")`
	height: calc(100vh - 120px);
	background: rgb(240, 240, 240);
	padding-top: 120px;
	text-align: center;
	div {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}
`

const NotFound = () => {
	return (
		<NotFoundWrapper>
			<div>
				<h1>404</h1>
				<h2>Apologies, but the page you requested could not be found.</h2>
			</div>
		</NotFoundWrapper>
	)
}
export default NotFound
