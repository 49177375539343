import React, { useEffect, useState, useRef } from "react"
import { StyledButton } from "../../ui/Button"
import styled from "@emotion/styled"

const StyledForm = styled("form")`
	text-align: right;
	textarea {
		width: 100%;
		resize: none;
		height: 200px;
	}
`

const ContactForm = ({ user }) => {
	const [submit, setSubmit] = useState(false)
	const messageRef = useRef()
	useEffect(() => {
		if (submit && messageRef.current.value.length > 0) {
			var data = { message: messageRef.current.value }

			fetch(
				"https://admin.wideco.se/mail.php?message=" + messageRef.current.value,
				{
					method: "GET",
					mode: "cors",
					headers: {
						"Access-Control-Allow-Origin": "*"
					}
				}
			)
				.then(function(res) {
					return res.json() //error here
					messageRef.current.value = ""
				})
				.then(function(data) {
					messageRef.current.value = ""
				})
				.catch(error => {
					messageRef.current.value = ""
				})
		}
	}, [submit])

	return (
		<StyledForm>
			<textarea ref={messageRef} placeholder="Write us a message!"></textarea>
			<StyledButton
				onClick={e => {
					e.preventDefault()
					setSubmit(true)
				}}
			>
				Send
			</StyledButton>
			{submit && <p>Message has been sent!</p>}
		</StyledForm>
	)
}
export default ContactForm
