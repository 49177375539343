import React, { useState, useEffect, Fragment } from "react"
import { sizes } from "../Theme"

export const Below = ({ size, children }) => {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (typeof window !== "undefined") {
			const breakpoint = Number.isInteger(size) ? size : sizes[size]
			if (breakpoint < window.innerWidth) {
				setVisible(false)
			} else {
				setVisible(true)
			}
		}
	})

	if (!visible) return null

	return <Fragment>{children}</Fragment>
}

export const Above = ({ size, children }) => {
	const [visible, setVisible] = useState(false)

	useEffect(() => {
		if (typeof window !== "undefined") {
			const breakpoint = Number.isInteger(size) ? size : sizes[size]
			if (breakpoint > window.innerWidth) {
				setVisible(false)
			} else {
				setVisible(true)
			}
		}
	})

	if (!visible) return null

	return <Fragment>{children}</Fragment>
}
