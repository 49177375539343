import React, { useState, useContext } from "react"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import { Below, Above } from "../../utils/Helpers"
import { ReactComponent as Logo } from "../../img/logo.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faSearch } from "@fortawesome/pro-light-svg-icons"
import Search from "./Search"
import Menu from "./Menu"

import TopHeader from "../topHeader/TopHeader"
import { LanguageContext } from "../../language/LanguageProvider"

import { loader } from "graphql.macro"
const HomeQuery = loader("../../queries/HomeQuery.gql")

const StyledHeader = styled("header")`
    display: flex;
    background: #383838;
    justify-content: center;
    align-items: center;
    position: fixed;
    color: #fff;
    padding: 15px;
    width: 100%;
    z-index: 99;
    top: 0;
    ${props => props.theme.bp.below.md} {
        .logo {
            width: 90px;
            margin-top: 5px;
        }
    }
    ${props => props.theme.bp.above.md} {
        justify-content: space-between;
        padding: 0 1.7rem;
        top: 23px;
        min-height: 72px;
        .logo {
            width: 120px;
            padding-top: 5px;
        }
    }
    input {
        padding: 10px 45px;
        text-align: right;
        font-family: "Rajdhani", sans-serif;
    }
`
const ToggleButton = styled("button")`
    color: ${props => (props.isOpen ? "#383838" : "#FFF")};
    background: ${props => (props.isOpen ? "#FFF" : "transparent")};
    border: none;
    position: absolute;
    height: 100%;
    font-size: 24px;
    &:focus {
        outline: none;
    }
    &.menu {
        padding: 0 20px;
        left: 0;
    }
    &.search {
        padding: 0 20px;
        right: 0;
    }
`

const Header = ({ client }) => {
    const [menuOpen, openMenu] = useState(false)
    const [searchOpen, openSearch] = useState(false)
    const [openId, setOpenId] = useState(null)
    const { activeLanguage } = useContext(LanguageContext)

    return (
        <StyledHeader className="main-header">
            <Above size="md">
                <TopHeader client={client} />
            </Above>
            <Below size="md">
                <ToggleButton
                    onClick={() => openMenu(!menuOpen)}
                    className="menu">
                    <FontAwesomeIcon icon={faBars} />
                </ToggleButton>
            </Below>

            <Link
                onClick={() => {
                    window.scrollTo(0, 0)
                }}
                onMouseOver={() => {
                    client.query({
                        query: HomeQuery,
                        variables: {
                            pageId: 7,
                            lang: activeLanguage
                        }
                    })
                }}
                to={`/${activeLanguage}`}>
                <Logo className="logo" />
            </Link>

            <Below size="md">
                <Menu
                    menuOpen={menuOpen}
                    setOpenId={setOpenId}
                    openMenu={openMenu}
                    openId={openId}
                    openSearch={openSearch}
                />
            </Below>

            <Above size="md">
                <Menu
                    menuOpen={true}
                    openMenu={openMenu}
                    openSearch={openSearch}
                    searchOpen={searchOpen}
                    openId={openId}
                    setOpenId={setOpenId}
                />
            </Above>
        </StyledHeader>
    )
}

export default Header
