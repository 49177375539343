import React from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"

export const StyledColumn = styled("div")`
	width: ${props =>
		props.attributes.width ? props.attributes.width + "%" : "auto"};
	iframe {
		width: 100%;
	}
	display: flex;
	flex-direction: column;
	> * {
		height: 100%;
	}
	img {
		object-fit: cover;
	}
	${props => props.theme.bp.below.md} {
		width: 100%;
	}
`

const Column = ({ block, user }) => {
	return (
		<StyledColumn className="column" attributes={block.attributes}>
			{block.innerBlocks && block.innerBlocks.length > 0 && (
				<>
					{block.innerBlocks.map((child, index) => (
						<BlockRenderer user={user} key={index} block={child} />
					))}
				</>
			)}
		</StyledColumn>
	)
}

export default Column
