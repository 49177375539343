import React, { useEffect } from "react"

import { Query } from "react-apollo"

import { loader } from "graphql.macro"
const HomeQuery = loader("./queries/HomeQuery.gql")

import "./Home.css"
import BlockRenderer from "./BlockRenderer"
import Loader from "./ui/Loader"

const Home = ({ match: { params } }) => {
    const pageId = 6466
    const { lang } = params
    
    return (
        <div className="Home home-page">
            <Query query={HomeQuery} variables={{ pageId, lang }}>
                {({ loading, error, data }) => {
                    if (loading) return <Loader />
                    if (error) {
                        return null
                    }

                    var blocks = []
                    if (data.pageBy && data.pageBy.blocks) {
                        blocks = data.pageBy.blocks
                    }else{
                        return null
                    }

                    return (
                        <>
                            <React.Fragment>
                                {blocks.map((block, index) => {
                                    return (
                                        <BlockRenderer
                                            key={index}
                                            block={block}
                                        />
                                    )
                                })}
                            </React.Fragment>
                        </>
                    )
                }}
            </Query>
        </div>
    )
}

export default Home
