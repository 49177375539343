import React, { useEffect } from "react"

import styled from "@emotion/styled"
import { CoverStyles } from "../styles/Cover"
import { dateFormatter } from "../utils/dateFormatter"
import { fonts } from "../Theme"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/pro-light-svg-icons"

const StyledPopup = styled("div")`
	position: fixed;
	width: 100%;
	max-width: 1100px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 999;
	background: #fff;
	padding: 5rem;
	height: 80%;
	overflow: scroll;
	${props => props.theme.bp.below.md} {
		padding: 15px;
	}
	span,
	p {
		position: relative;
		font-family: ${fonts.body};
		text-transform: none;
		letter-spacing: 0;
		bottom: auto;
	}
	time,
	h1 {
		text-align: center;
		display: block;
	}
	time {
		color: #83b5b0;
		font-family: ${fonts.headings};
		margin-bottom: 1rem;
	}
`

const PopupContent = styled("div")`
	width: 800px;
	max-width: 100%;
	margin: 0 auto;
`

const CloseButton = styled(FontAwesomeIcon)`
	position: absolute;
	font-size: 40px;
	top: 1em;
	right: 1em;
	cursor: pointer;
`

const Cover = styled("div")`
	${CoverStyles};
	background: rgba(0, 0, 0, 0.6);
	z-index: 900;
`

const PostPopup = ({ post, parameters, popup, setPopup, postType }) => {
	useEffect(() => {
		window.history.pushState("string", post.title, post.slug)
	})
	return (
		<>
			<Cover
				onClick={() => {
					window.history.pushState(
						"string",
						post.title,
						window.location.href.replace(post.slug, "")
					)
					setPopup(false)
				}}
			/>
			<StyledPopup>
				<CloseButton
					onClick={() => {
						window.history.pushState(
							"string",
							post.title,
							window.location.href.replace(post.slug, "")
						)
						setPopup(false)
					}}
					icon={faTimes}
				/>
				<PopupContent>
					{postType !== "reference" ? (
						<>
							<time>{dateFormatter(post.date)}</time>
							<h1>{post.title}</h1>
						</>
					) : (
						<h1>{post.title}</h1>
					)}
					<div dangerouslySetInnerHTML={{ __html: post.content }} />
				</PopupContent>
			</StyledPopup>
		</>
	)
}
export default PostPopup
