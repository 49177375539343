import React, { useState } from "react";

import { withRouter } from "react-router-dom";

export const LanguageContext = React.createContext();

const LanguageProvider = ({ children, history, lang }) => {
  const [language, setLanguage] = useState(lang);

  const refLinks = {
    sv: "/sv/wideco-3/kund-case",
    en: "/en/wideco-3/business-cases-2",
    fr: "/fr/references/",
  };

  const blogLinks = {
    sv: "/sv/nyheter/",
    en: "/en/blog-2/",
    fr: "/fr/news-fr/",
  };

  return (
    <LanguageContext.Provider
      value={{
        activeLanguage: language,
        languages: setLanguage,
        refLink: refLinks[language],
        blogLink: blogLinks[language],
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
export default withRouter(LanguageProvider);
