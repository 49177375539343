import React from "react"

import css from "@emotion/css"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/core"

import { cardStyles } from "../styles/Card"

import bgImage from "../img/placeholder-top.jpg"

const loadAnimation = keyframes`
	0% {
	  transform: translate3d(-30%, 0, 0); }
	100% {
	  transform: translate3d(30%, 0, 0); } }
  `

const gradient = css`
content: " ";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    z-index: 1;
    width: 500%;
    margin-left: -250%;
    -webkit-animation: ${loadAnimation} 2.5s linear infinite;
    animation: ${loadAnimation} 2.5s linear infinite;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 46%, rgba(255, 255, 255, 0.35) 50%, rgba(255, 255, 255, 0) 54%) 50% 50%;
}
`

const round = keyframes`
0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(720deg);
    transform: rotate(720deg);
  }
}

`

const LoadingWrapper = styled("div")`
	height: 100vh;
	background: rgb(240, 240, 240);
	padding-top: 85px;
	.Home & {
		height: calc(100vh + 300px);
		margin-top: -85px;
	}
	* {
		position: relative;
		overflow: hidden;
	}
`
const PlaceHolderCover = styled("div")`
	height: 50vh;
	.Home & {
		height: calc(100vh + 200px);
		margin-top: -85px;
	}
	background-color: #ced4da;
	background-image: url(${bgImage});
	background-size: cover;
	background-position: center center;
	filter: blur(8px);
	width: 100%;
	margin-bottom: -60px;
	&:before {
		${gradient};
	}
`

const PlaceHolderCard = styled("div")`
	${cardStyles};
	height: 250px;
`

const PlaceHolderText = styled("span")`
	display: block;
	background: #e8ebef;
	max-width: 90%;
	&.heading {
		width: 500px;
		margin: 0 auto;
		height: 54px;
		margin-bottom: 24px;
	}
	&.paragraph {
		width: 500px;
		margin: 0 auto;
		height: 20px;
		margin-bottom: 8px;
	}

	&:before {
		${gradient};
	}
`

const Loader = () => {
	return (
		<LoadingWrapper>
			<PlaceHolderCover />
			<PlaceHolderCard>
				<PlaceHolderText className="heading" />
				<PlaceHolderText className="paragraph" />
				<PlaceHolderText className="paragraph" />
				<PlaceHolderText className="paragraph" />
			</PlaceHolderCard>
		</LoadingWrapper>
	)
}
export default Loader
