import css from "@emotion/css"
import { bp } from "../Theme"

export const contactStyles = props => css`
	&.contact-text {
		position: relative;
		padding: 40px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		& > div {
			${bp.below.md} {
				position: relative;
				bottom: auto;
			}
			& > div {
				padding: 0 10px 0 10px;
			}
		}
		h2 {
			font-size: 3rem;
			padding-left: 0;
			font-weight: 300;
			padding-bottom: 1rem;
		}
		p {
			font-family: "Rajdhani", sans-serif;
			line-height: 25px;
			strong {
				font-weight: bold;
			}
		}
	}
`;

export const contactPostsStyles = props => css`
	article {
		margin-left: 1.5rem;
		margin-right: 1.5rem;
		${bp.below.md} {
			margin-left: 0;
			margin-right: 0;
		}
		& > div {
			padding-bottom: 3.2rem;
			padding-top: 0;
			font-size: 1rem;
			& > p:first-of-type {
				font-family: "Rajdhani", sans-serif;
				opacity: 0.6;
			}
		}
		h3 {
			font-size: 1.4rem;
			padding-bottom: 0;
			padding-top: 3rem;
		}
		p {
			margin-bottom: 0;
			padding-top: 0.5rem;
			font-family: "Helvetica Neue";
		}
	}
`;
