import React, { useState, useContext } from "react"
import styled from "@emotion/styled"

import { Link } from "react-router-dom"

import { Query } from "react-apollo"
import { loader } from "graphql.macro"
import { CoverStyles } from "../../styles/Cover"
import { LanguageContext } from "../../language/LanguageProvider"
const SearchQuery = loader("../../queries/SearchQuery.gql")

const SearchWrapper = styled("div")`
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	input {
		width: 100%;
		text-align: right;
		border: 0;
		&:focus {
			outline: none;
		}
	}
`

const SearchResult = styled("ul")`
	padding: 0;
	list-style: none;
	color: #000;
	text-align: right;
	padding-right: 45px;
	background: rgba(60, 60, 60, 0.9);
    padding-top: 10px;
    padding-bottom: 10px;
    color: #fff;
	}
	li {
		padding-top: 4px;
		padding-bottom: 4px;
		a {
			color: #fff;
			font-family: "Rajdhani",sans-serif;
			&:hover {
				text-decoration: underline;
			}
		}
	}
`

const Cover = styled("div")`
	${CoverStyles}
`

const Search = ({ openSearch }) => {
	const [term, setTerm] = useState(false)
	const { activeLanguage } = useContext(LanguageContext)
	var lang = activeLanguage.key === "en" ? "en" : "sv"
	return (
		<>
			<Cover onClick={() => openSearch(false)} />
			<SearchWrapper>
				<input
					autoFocus
					onChange={event => setTerm(event.target.value)}
					placeholder="What are you looking for?"
				/>
				{term && term.length > 2 && (
					<Query query={SearchQuery} variables={{ term, lang }}>
						{({ loading, error, data }) => {
							if (loading) return null
							if (error) return null

							return (
								<SearchResult>
									{data.searchPages.map((page, index) => {
										if (page === null) return null
										return (
											<li key={index}>
												<Link
													onClick={() => openSearch(false)}
													to={activeLanguage.url + page.uri}
												>
													{page.title}
												</Link>
											</li>
										)
									})}
								</SearchResult>
							)
						}}
					</Query>
				)}
			</SearchWrapper>
		</>
	)
}

export default Search
