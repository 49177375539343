import React, { useEffect, useRef } from "react"
import styled from "@emotion/styled"

import Cover from "./ui/Cover"
import Button from "./ui/Button"
import Buttons from "./ui/Buttons"
import Card from "./ui/Card"
import Image from "./ui/Image"
import Slider from "./ui/Slider"
import Slide from "./ui/Slide"
import Group from "./ui/Group"
import Background from "./ui/Background"
import Posts from "./Posts"
import Accordion from "./ui/Accordion"
import AccordionItem from "./ui/AccordionItem"
import Columns from "./ui/Columns"
import Column from "./ui/Column"
import Spacer from "./ui/Spacer"
import ShortCodeRenderer from "./ui/ShortcodeRenderer"
import Separator from "./ui/Separator"
import Stepper from "./ui/Stepper"
import Gallery from "./ui/Gallery"
import File from "./ui/File"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlayCircle } from "@fortawesome/pro-light-svg-icons"
import { library } from "@fortawesome/fontawesome-svg-core"

library.add(faPlayCircle)

const StyledDefault = styled("div")`
    padding: 10px;
    .play-icon {
        margin-right: 1rem;
    }
`

const Default = ({ originalContent, className }) => {
    const contentRef = useRef()
    const iconRef = useRef()
    useEffect(() => {
        var mp4 = new RegExp("http?.*?.mp4")
        if (mp4.test(originalContent)) {
            var icon = iconRef.current.innerHTML
            var links = contentRef.current.getElementsByTagName("a")
            for (let i = 0; i < links.length; i++) {
                const link = links[i]
                if (mp4.test(link.getAttribute("href"))) {
                    link.innerHTML = icon + link.innerHTML
                }
            }
        }
        //iconRef.current.remove()
    }, [originalContent])
    return (
        <>
            <div style={{ display: "none" }} ref={iconRef}>
                <FontAwesomeIcon className="play-icon" icon={faPlayCircle} />
            </div>
            <StyledDefault
                ref={contentRef}
                className={className}
                dangerouslySetInnerHTML={{
                    __html: originalContent,
                }}
            />
        </>
    )
}

const BlockRenderer = ({ block, user }) => {
    const content = {
        __dangerousHTML: block.originalContent,
    }

    switch (block.__typename) {
        case "CoreCoverBlock":
            return <Cover block={block} />
            break
        case "CoreButtonBlock":
            return <Button block={block} />
            break
        case "CoreButtonsBlock":
            return <Buttons block={block} />
            break
        case "R3CardBlock":
            return <Card block={block} />
            break
        case "CoreImageBlock":
            return <Image block={block} />
            break
        case "R3SliderBlock":
            return <Slider block={block} />
            break
        case "R3SlideBlock":
            return <Slide block={block} />
            break
        case "CoreGroupBlock":
            return <Group block={block} />
            break
        case "R3BackgroundBlock":
            return <Background block={block} />
            break
        case "CoreLatestPostsBlock":
            return <Posts block={block} />
            break
        case "R3AccordionBlock":
            return <Accordion block={block} />
            break
        case "R3AccordionItemBlock":
            return <AccordionItem block={block} />
            break
        case "CoreColumnsBlock":
            return <Columns user={user} block={block} />
            break
        case "CoreColumnBlock":
            return <Column user={user} block={block} />
            break
        case "CoreSpacerBlock":
            return <Spacer block={block} />
            break
        case "CoreSeparatorBlock":
            return <Separator block={block} />
            break
        case "R3BlockStepperBlock":
            return <Stepper block={block} />
            break
        case "CoreGalleryBlock":
            return <Gallery block={block} />
            break
        case "CoreFileBlock":
            return <File block={block} />
            break
        case "CoreHtmlBlock":
            return (
                <div
                    dangerouslySetInnerHTML={{
                        __html: block.originalContent,
                    }}
                />
            )
            break
        case "CoreShortcodeBlock":
            return <ShortCodeRenderer block={block} user={user} />
            break
        default:
            return (
                <Default
                    className={
                        block.__typename === "CoreHeadingBlock" && "heading"
                    }
                    originalContent={block.originalContent}
                />
            )
    }
    return <div />
}

export default BlockRenderer
