import React, { useState, useContext } from "react"
import { Post, PostWrapper, PostContent, Category } from "./Components"
import { StyledButton } from "../ui/Button"

import { Link } from "react-router-dom"
import { LanguageContext } from "../language/LanguageProvider"

const Reference = ({ post, setPopup }) => {
	const [hover, setHover] = useState(false)
	const { activeLanguage,refLink } = useContext(LanguageContext)
	const languageButtonTextHandler = () => {
		switch (activeLanguage) {
			case "fr":
				return "En savoir plus";
			case "sv":
				return "Läs mer";
			default:
				return "Read more";
		}
	}

	return (
		<Post
			className={"reference " + (hover ? "hover" : "")}
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			{post.featuredImage && post.featuredImage.sourceUrl && (
				<Link
					style={{ marginTop: 0 }}
					onClick={() => {
							if (setPopup) {
								setPopup(post.id)
							}
						}}
					to={!setPopup && refLink + '?open=' +post.slug}
				>
					<img src={post.featuredImage.sourceUrl} />
				</Link>
			)}
			<PostWrapper>
				<Category>
					{post.categories.nodes.length > 0 && post.categories.nodes[0].name}
				</Category>
				<Link
					onClick={() => {
							if (setPopup) {
								setPopup(post.id)
							}
						}}
					to={!setPopup && refLink + '?open=' +post.slug}
				>
					<h3 dangerouslySetInnerHTML={{ __html: post.title }}></h3>
				</Link>
				<PostContent>
					<StyledButton
						onClick={() => {
							if (setPopup) {
								setPopup(post.id)
							}
						}}
						to={!setPopup && refLink + '?open=' +post.slug}
					>
						{languageButtonTextHandler()}
					</StyledButton>
				</PostContent>
			</PostWrapper>
		</Post>
	)
}
export default Reference
