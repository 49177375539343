export const dateFormatter = (date,locale="en") => {
	date = date.split("T")[0]
	date = new Date(date)
	var options = {
		year: "numeric",
		month: "long",
		day: "numeric"
	}

	const locales = {
		sv:"sv-SE",
		fr:"fr-FR",
		en:"en-GB"
	}
	return date.toLocaleDateString(locales[locale], options)
}
