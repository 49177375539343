import React, { useState, createContext, useContext } from "react"
import styled from "@emotion/styled"
import BlockRenderer from "../BlockRenderer"

import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTriangle } from "@fortawesome/pro-solid-svg-icons"
library.add(faTriangle)

const StyledStepper = styled("div")`
	flex: 1 1 auto;
	width: 100%;
	margin: 0 auto;
	max-width: 1100px;
	.background {
		padding: 50px 30px;
	}
`
const Step = styled("div")`
	text-align: center;
	position: relative;
	margin-bottom: 50px;
	padding-top: 30px;
	.button:not(.open) ~ * {
		display: none;
	}
	.button.open {
		display: none;
	}
	img {
		width: 80px;
		margin: 20px auto;
		height: auto;
	}
	h2 {
		font-size: 1.5rem !important;
		padding-bottom: 0 !important;
		line-height: 1em !important;
		color: #3a7b77;
		strong {
			font-weight: bold !important;
		}
	}
	p {
		font-size: 1rem;
		max-width: 700px;
		margin: 0 auto 1rem;
	}
	span {
		display: inline-block;
		background: #3a7b77;
		width: 45px;
		height: 45px;
		border-radius: 45px;
		color: #fff;
		font-weight: 400;
		line-height: 45px;
		position: absolute;
		top: 30px;
		transform: translateY(-50%) translateX(-50%);
	}
`

const Next = styled("div")`
	margin-top: -20px;
	&:before {
		display: block;
		margin: 0 auto;
		width: 0;
		border-left: 2px dotted #3a7b77;
		content: "";
		height: 50px;
	}
	svg {
		transform: rotate(180deg);
		color: #3a7b77;
	}
`
export const StepperContext = createContext()
const StepperProvider = ({ children }) => {
	const [openIndexes, setOpenIndexes] = useState([])
	return (
		<StepperContext.Provider value={{ hasStepper: true }}>
			{children}
		</StepperContext.Provider>
	)
}

const Stepper = ({ block }) => {
	return (
		<StepperProvider>
			<StyledStepper>
				{block.innerBlocks && block.innerBlocks.length > 0 && (
					<>
						{block.innerBlocks.map((child, index) => (
							<Step>
								<span>{index + 1 + "."}</span>
								<BlockRenderer key={index} block={child} />
								{index + 1 < block.innerBlocks.length && (
									<Next>
										<FontAwesomeIcon icon={faTriangle} />
									</Next>
								)}
							</Step>
						))}
					</>
				)}
			</StyledStepper>
		</StepperProvider>
	)
}

export default Stepper
