import React from "react"
import styled from "@emotion/styled"

import BlockRenderer from "../BlockRenderer"

const StyledGroup = styled("div")`
	&.image-text {
		&.narrow {
			.text {
				padding-bottom: 3rem;
				padding-left: 2rem;
				padding-right: 2rem;
			}
			img {
				position: relative;
			}
			p {
				${props => props.theme.bp.below.md} {
					text-align: center !important;
				}
			}
			${props => props.theme.bp.above.lg} {
				max-width: 1100px;
				margin: 0 auto;
				padding: 90px 50px;
				position: relative;
				img {
					width: 50%;
					display: inline-block;
				}
				.text {
					max-width: 50%;
					display: inline-block;
					vertical-align: top;
					text-align: left;
					padding-right: 0;
					h2 {
						font-size: 1.7rem;
					}
					p {
						font-size: 1rem;
					}
					a {
						margin-left: 10px;
					}
				}
			}
		}
	}
	&.image-text {
		&.wide {
			.text {
				padding-top: 2rem;
				padding-bottom: 1.5rem;
			}
			${props => props.theme.bp.above.lg} {
				.background {
					padding: 0;
					& > div {
						display: flex;
					}
					.text {
						padding: 120px;
					}
				}
			}
		}
	}
`

const Group = ({ block }) => {
	return (
		<StyledGroup className={block.attributes && block.attributes.className}>
			{block.innerBlocks && block.innerBlocks.length > 0 && (
				<React.Fragment>
					{block.innerBlocks.map((child, index) => (
						<BlockRenderer key={index} block={child} />
					))}
				</React.Fragment>
			)}
		</StyledGroup>
	)
}

export default Group
