import styled from "@emotion/styled"

import { cardStyles } from "../styles/Card"

export const Category = styled("span")`
	position: relative !important;
	color: #858585;
	font-size: 14px !important;
	letter-spacing: 1px !important;
	.references-page & {
		padding-top: 3rem;
	}
`

export const Post = styled("article")`
	${cardStyles};
	margin-bottom: 33px;
	text-align: center;
	padding: 0 !important;
	position:relative;
	&.contact {
		padding:0;
		margin:25px;
		${props => props.theme.bp.above.md} {
			width:calc(33% - 50px);
		}
		img {
			width: 100%;
		}
	}

	h3 {
		padding-top: 1.3rem;
		padding-bottom: 8px;
		font-size: 1.6rem !important;
		font-weight: 500;
		padding-left: 20px;
		padding-right: 20px;
		line-height: 27px;
		min-height:3em;
	}
	&.reference h3 {
		padding-top:0;
		padding-bottom:2rem;
		.references-page & {
			padding-bottom:0;
		}
	}
	div {
		padding: 20px 15px;
		text-align: center;
	}
	img {
		width: 100%;
		.references-page & {
			margin-bottom:1.5rem;
		}
	}
	
	&.reference, &.blog-posts {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 3rem !important;

		${props => props.theme.bp.below.md} {
			display: none;
			&:first-of-type {
				display: flex;
			}
		}
	
	&.reference {

		${props => props.theme.bp.above.md} {
		overflow:hidden;
		justify-content: end;
		margin-bottom: 6rem;
		div:not(.references-page) > & {
		padding-bottom: 150px !important;
		}
		${props => props.theme.bp.below.md} {
			padding-bottom:90px;
		}
		padding:0;
		.references-page & {
			display:block;
		}
	}

	
	&.blog-posts {
		padding-top: 50px;
		padding-bottom: 50px;
		display:flex;
		flex-direction:column;
		justify-content:space-between;
		display: inline-block;
		width: 30%;
		vertical-align: text-top;
		.post-content {
			padding:0;
		}
		${props => props.theme.bp.below.md} {
			width: 100%;
			padding: 0;
			padding-bottom: 50px;
			.post-content {
				padding-top: 50px;
			}
		}
	}
	${props => props.theme.bp.above.md} {
		margin-left: 2.5%;
		margin-right: 2.5%;
		margin-bottom: 0;
		.references-page &.reference {
			margin-left:0;
			margin-right:0;
			margin-bottom:20px;
			padding-bottom:0 !important;
		}
		.blog &.blog-posts {
			display:inline-block;
			padding-top:0;
			.post-content {
				padding-top:40px;
			}
		}
		&:first-of-type {
			margin-left: 0;
		}
		&:last-of-type {
			margin-right: 0;
		}

	}
`

export const PostWrapper = styled("div")`
	position: relative;
	padding: 0 !important;
	background: #fff;
	transition: 0.4s;
	div:not(.references-page) > .reference & {
		${props => props.theme.bp.above.md} {
			position: absolute;
			top: 100%;
			margin-top: -105px;
			height: 100%;
			width: 100%;
		}
	}
	div:not(.references-page) > .reference.hover & {
		${props => props.theme.bp.above.md} {
			top: 0;
			margin-top: 0;
		}
	}
	${props => props.theme.bp.above.md} {
		padding-bottom: 2rem !important;
	}
`
export const PostContent = styled("div")`
	div:not(.references-page) > .reference > & {
		${props => props.theme.bp.above.md} {
			opacity: 0;
			visibility: hidden;
		}
		width: 100%;
		transition: 0.4s;
		div {
			padding: 0;
		}
		.hover & {
			opacity: 1;
			visibility: visible;
		}
	}
`
