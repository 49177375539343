import React, { useState, useContext } from "react"

import { Query } from "react-apollo"
import { loader } from "graphql.macro"
const CategoriesQuery = loader("./queries/CategoriesQuery.gql")
import queryString from 'query-string'
import { LanguageContext } from "./language/LanguageProvider"

import styled from "@emotion/styled"
import Posts from "./Posts"
import Page from "./Page"
import MaxWidth from "./layout/MaxWidth"

const ReferencesContainer = styled("div")`
	background: #f0f0f0;
	min-height: 100vh;
	> div {
		min-height: 0;
	}
	.posts-wrapper {
		background: none;
		max-width: 1100px;
		margin: 0 auto;
		column-count: 3;
		display: block;
		column-gap: 2rem;
		overflow: auto;
		padding: 0;
		padding-top: 1rem;
		padding-bottom: 5rem;
		article {
			margin: 0;
			margin-bottom: 2rem;
			width: auto;
			padding: 40px;
			display: inline-block;
		}
		${props => props.theme.bp.below.sm} {
			max-width: 100%;
			column-count: 1;
			column-gap: 0;
			padding-top: 0;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			article {
				max-width: 100%;
			}
		}
	}
`

const Categories = styled("ul")`
	list-style: none;
	margin: 0;
	padding: 0 0 0 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: start;
	align-content: space-evenly;
	max-width: 1100px;
	margin: 0 auto;
	${props => props.theme.bp.below.sm} {
		max-width: 100%;
		padding-left: 1.5rem;
		padding-right: 1.5rem;
		margin-bottom: 1rem;
	}
`
const Category = styled("li")`
	border: 1px solid #7faaa7;
	padding: 5px 18px;
	margin-bottom: 5px;
	color: #7faaa7;
	text-transform: uppercase;
	font-size: 13px;
	cursor: pointer;
	margin-right: 1rem;
	font-family: "Rajdhani", sans-serif;
	font-size: 1rem;
	&:hover,
	&.active {
		background: #7faaa7;
		color: #fff;
	}
	${props => props.theme.bp.below.sm} {
		margin-right: 1rem;
		margin-bottom: 1rem;
	}
`

const References = props => {
	const parsed = queryString.parse(props.location.search)
	const [catId, setCatId] = useState(false)
	const [popup, setPopup] = useState(parsed.open ? parsed.open : null)

	const { activeLanguage } = useContext(LanguageContext)
	var variables = {
		language: activeLanguage.toUpperCase(),
		postType: "REFERENCES"
	}

	return (
		<ReferencesContainer>
			<Page {...props} />
			<Query query={CategoriesQuery} variables={variables}>
				{({ loading, error, data }) => {
					if (loading) return null
					if (error) console.warn(error)

					if (data.categories && data.categories.nodes) {
						return (
							<Categories>
								{data.categories.nodes.map((category, index) => (
									<Category
										onClick={() => {
											if (catId === category.categoryId) {
												setCatId(false)
											} else {
												setCatId(category.categoryId)
											}
										}}
										key={index}
										className={catId === category.categoryId ? "active" : ""}
									>
										{category.name}
									</Category>
								))}
							</Categories>
						)
					}
				}}
			</Query>
			<Posts
				postType="references"
				className="references-page"
				parameters={{ orderBy: "DATE", categoryId: catId }}
				popup={popup}
				setPopup={setPopup}
			/>
		</ReferencesContainer>
	)
}

export default References
