import React from "react";
import styled from "@emotion/styled";
import BlockRenderer from "../BlockRenderer";
import { fonts } from "../Theme";

const StyledCover = styled("div")`
  background-image: url("${(props) => props.attributes.url}");
  background-size: cover;
  background-position: ${(props) =>
    props.focalPoint
      ? props.focalPoint.x * 100 + "% " + props.focalPoint.y * 100 + "%"
      : "center"};
  position: relative;
  width: ${(props) =>
    props.attributes.align === "center" ? "1100px" : "100%"};
  margin: ${(props) => (props.attributes.align === "center" ? "auto" : "0")};
  h1 {
    font-weight: 400;
  }
  p {
    font-family: ${fonts.headings} !important;
    strong {
      font-weight: 500;
    }
  }
  .page & {
    height: 50vh;
  }
  height: 100vh;
  max-width: 100% !important;
  margin-bottom: ${(props) =>
    props.attributes.marginBottom
      ? props.attributes.marginBottom + "px"
      : "auto"};
  margin-top: ${(props) =>
    props.attributes.marginTop ? props.attributes.marginTop + "px" : "0"};
  text-align: left;
  padding: 60px 20px;
  h1 {
    font-size: 50px;
    line-height: 45px;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
  > div {
    width: 100%;
    max-width: calc(1100px + 16vw);
    margin-top: 6rem;
    ${(props) => props.theme.bp.above.md} {
      position: absolute;
      padding: 0 8vw;
      top: 25vh;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      margin-top: 0;
      .Home & > .heading {
        max-width: 480px;
      }
      .page & {
        top: 50%;
        transform: translateY(-50%);
        padding-bottom: 0;
      }
      h1 {
        font-size: 5rem;
        line-height: 4.5rem;
      }
      div {
        padding-bottom: 1rem;
        padding-left: 0;
      }
    }
  }
  &.blog-cover {
    padding-top: 2.5rem;
    height: auto;
    .heading {
      max-width: none !important;
    }
    ${(props) => props.theme.bp.above.md} {
      padding: 6.5rem 20px;
      padding-bottom: 8.5rem;
    }
    .posts-wrapper {
      padding: 1rem 0 0 0;
      ${(props) => props.theme.bp.above.md} {
        padding-top: 4rem;
        padding-right: 0;
        padding-bottom: 4rem;
      }
      span {
        bottom: 55px;
        ${(props) => props.theme.bp.below.md} {
          bottom: 40px;
        }
        a {
          color: #858585;
          ${(props) => props.theme.bp.above.md} {
            color: #fff;
          }
        }
        ${(props) => props.theme.bp.above.md} {
          padding-bottom: 0;
          bottom: -2rem;
        }
      }
    }
    & > div {
      position: relative;
      left: auto;
      top: auto;
      margin: 0 auto;
      text-align: center;
      ${(props) => props.theme.bp.above.md} {
        max-width: 1100px;
      }
      & div {
        padding-bottom: 5px;
        padding-left: 30px;
        padding-right: 30px;
      }
      .wp-block-subhead {
        font-family: "Rajdhani", sans-serif;
        font-size: 1.1rem;
        letter-spacing: 2px;
      }
      h2 {
        font-family: "Rajdhani", sans-serif;
        font-size: 2rem;
      }
    }
  }
  &.back-image {
    max-height: 50vh;
  }
  .max-height & {
    height: 100%;
  }

  ${(props) => props.theme.bp.below.md} {
    margin-bottom: ${(props) =>
      props.attributes.mobileMarginBottom
        ? props.attributes.mobileMarginBottom + "px"
        : props.attributes.marginBottom
        ? props.attributes.marginBottom + "px"
        : "0"};
    margin-top: ${(props) =>
      props.attributes.mobileMarginTop
        ? props.attributes.mobileMarginTop + "px"
        : props.attributes.marginTop
        ? props.attributes.marginTop + "px"
        : "0"};
  }
`;

const InnerBlocks = styled("div")``;

const Cover = ({ block }) => {
  if (!block.attributes) return null;
  const { focalPoint } = block.attributes;

  return (
    <StyledCover
      className={"cover " + block.attributes.className}
      attributes={block.attributes}
      focalPoint={JSON.parse(focalPoint)}
    >
      {block.innerBlocks.length > 0 && (
        <InnerBlocks>
          {block.innerBlocks.map((child, index) => (
            <BlockRenderer key={index} block={child} />
          ))}
        </InnerBlocks>
      )}
    </StyledCover>
  );
};

export default Cover;
