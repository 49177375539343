import React, { useState, useContext } from "react"

import { Query } from "react-apollo"
import { loader } from "graphql.macro"
const CategoriesQuery = loader("./queries/CategoriesQuery.gql")
import queryString from 'query-string'

import styled from "@emotion/styled"
import Posts from "./Posts"
import Page from "./Page"
import { LanguageContext } from "./language/LanguageProvider"

const BlogContainer = styled("div")`
    background: #f0f0f0;
    min-height: 100vh;
    > div {
        min-height: 0;
    }
    .blog {
        background: none;
        margin: 0 auto;
        column-count: 3;
        display: block;
        column-gap: 2rem;
        overflow: auto;
        max-width: 1100px;
        padding: 10px 0;
        article {
            margin: 0;
            margin-bottom: 2rem;
            width: auto;
            display: inline-block;
            width: 100% !important;
        }
        ${props => props.theme.bp.below.md} {
            max-width: 100%;
            column-count: 1;
            column-gap: 0;
            padding-top: 0;
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            article {
                max-width: 100%;
            }
        }
    }
`

const Categories = styled("ul")`
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-content: space-evenly;
    max-width: 1100px;
    margin: 0 auto;
    ${props => props.theme.bp.below.sm} {
        max-width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-bottom: 1rem;
    }
`
const Category = styled("li")`
    border: 1px solid #7faaa7;
    padding: 5px 18px;
    margin-bottom: 5px;
    color: #7faaa7;
    text-transform: uppercase;
    font-size: 13px;
    cursor: pointer;
    margin-right: 1rem;
    font-family: "Rajdhani", sans-serif;
    font-size: 1rem;
    &:hover,
    &.active {
        background: #7faaa7;
        color: #fff;
    }
    ${props => props.theme.bp.below.sm} {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
`

const Blog = props => {
    const parsed = queryString.parse(props.location.search)

    const [catId, setCatId] = useState(false)
    const [popup, setPopup] = useState(parsed.open ? parsed.open :null)
    const { activeLanguage } = useContext(LanguageContext)
    var variables = {
        language: activeLanguage.toUpperCase(),
        postType: "POST"
    }
    return (
        <BlogContainer>
            <Page {...props} />
            <Query query={CategoriesQuery} variables={variables}>
                {({ loading, error, data }) => {
                    if (loading) return null
                    if (error) console.warn(error)
                    if (data.categories && data.categories.nodes) {
                        return (
                            <Categories>
                                {data.categories.nodes.map(
                                    (category, index) => (
                                        <Category
                                            onClick={() => {
                                                if (
                                                    catId ===
                                                    category.categoryId
                                                ) {
                                                    setCatId(false)
                                                } else {
                                                    setCatId(
                                                        category.categoryId
                                                    )
                                                }
                                            }}
                                            key={index}
                                            className={
                                                catId === category.categoryId
                                                    ? "active"
                                                    : ""
                                            }>
                                            {category.name}
                                        </Category>
                                    )
                                )}
                            </Categories>
                        )
                    }
                }}
            </Query>
            <Posts
                className="blog"
                parameters={{ orderBy: "DATE", categoryId: catId }}
                popup={popup}
                setPopup={setPopup}
            />
        </BlogContainer>
    )
}

export default Blog
